import { FormControlLabel, Switch, TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import dataStore from "../../store/store";
import useData from "../../store/useData";
import ButtonSave from "../layout/buttons/ButtonSave";
import FormControl from "../layout/components/FormControl";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";

export default function SimulationRealTimeFactor(props) {
    const isDynamic = useData().isDynamicTimeStep();
    const realTimeFactor = useData().realTimeFactor();
    const [userRealTimeFactor, setUserRealTimeFactor] = useState(realTimeFactor);

    const enqueueMessage = dataStore.get.enqueueMqttMessage();

    const changeValue = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === "dynamic") {
            if (checked !== isDynamic) {
                enqueueMessage({
                    topic: "gui/event/dynamic_time_step",
                    message: checked ? "true" : "false",
                });
            }
            return;
        }

        if (name === "real_time_factor") {
            setUserRealTimeFactor(value);
            return;
        }
    };

    const saveRtf = () => {
        const newRtf = parseFloat(userRealTimeFactor);
        if (!isNaN(newRtf) && newRtf !== realTimeFactor) {
            console.log("Setting RTF to " + newRtf);
            enqueueMessage({
                topic: "gui/info/real_time_factor",
                message: newRtf.toString(),
            });
        }
    };

    useEffect(() => {
        setUserRealTimeFactor((s) => (s !== realTimeFactor ? realTimeFactor : s));
    }, [realTimeFactor]);

    return (
        <Fragment>
            <GridItem1>Real Time Factor</GridItem1>
            <GridItem2>
                <FormControl>
                    <FormControlLabel
                        name="dynamic"
                        value="dynamic"
                        label="Dynamic"
                        sx={{
                            color: isDynamic ? "inherit" : "gray"
                        }}
                        checked={isDynamic}
                        control={<Switch size="large" />}
                        onChange={changeValue}
                    />
                </FormControl>
            </GridItem2>
            {!isDynamic && (
                <Fragment>
                    <GridItem1>
                        <TextField
                            name="real_time_factor"
                            type="number"
                            min="0.01"
                            max="20.00"
                            step="0.1"
                            onChange={changeValue}
                            value={userRealTimeFactor}
                        />
                    </GridItem1>
                    <GridItem2>
                        <ButtonSave onClick={saveRtf} />
                    </GridItem2>
                </Fragment>
            )}
        </Fragment>
    );
}
