import { FormControlLabel, Switch } from "@mui/material";
import React, { Fragment } from "react";
import dataStore from "../../store/store";
import useData from "../../store/useData";
import FormControl from "../layout/components/FormControl";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";

export default function SimulationBlackouts(props) {
    const blackouts = useData().blackouts();
    const enqueueMessage = dataStore.get.enqueueMqttMessage();

    const changeValue = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === "blackouts") {
            dataStore.set.blackouts(checked);
            console.log("Blackouts", checked);
            enqueueMessage({
                topic: "gui/event/blackouts",
                message: checked ? "on" : "off",
            });
        }
    };

    return (
        <Fragment>
            <GridItem1>Blackouts</GridItem1>
            <GridItem2>
                <FormControl>
                    <FormControlLabel
                        name="blackouts"
                        onChange={changeValue}
                        checked={blackouts}
                        value={blackouts}
                        control={<Switch />}
                    />
                </FormControl>
            </GridItem2>
        </Fragment>
    );
}
