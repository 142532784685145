import { useMqttState } from "mqtt-react-hooks";
import { Fragment, useEffect } from "react";
import { MqttQos } from "../../lib/mqtt/PiMqttHelpers";
import dataStore, { actions, useStore } from "../../store/store";

/**
 *
 * @param {{ children: any }} props
 * @returns
 */
export default function InitialiseMqtt(props) {
    const isConnected = dataStore.use.isMqttConnected();
    const isMqttInitialised = dataStore.use.isMqttInitialised();
    const isPresetsFetched = useStore().data.isPresetsFetched();
    const mqttState = useMqttState();
    const connectionStatus = mqttState.connectionStatus;
    const isMqttMessageHandlersAdded = useStore().data.isMqttMessageHandlersAdded();

    /**
     * Update connected state as MQTT state changes
     */
    useEffect(() => {
        const checkConnected = connectionStatus === "Connected";
        if (!checkConnected) {
            dataStore.set.isMqttInitialised(false);
            dataStore.set.isMqttSubscribed(false);
            actions.data.isMqttMessageHandlersAdded(false);
        }
        if (checkConnected !== isConnected) {
            dataStore.set.isMqttConnected(checkConnected);
        }
    }, [connectionStatus]);

    /**
     * Request required MQTT data once message handlers are added
     */
    useEffect(() => {
        if (isPresetsFetched || !isMqttMessageHandlersAdded || !isConnected) {
            return;
        }
        mqttState.client.publish("preset", "send_presets", { qos: MqttQos.ATLEAST_ONCE });
    }, [isMqttMessageHandlersAdded]);

    return <Fragment>{props.children}</Fragment>;
}
