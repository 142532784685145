import Button from "./Button";
export default function ButtonMore(props) {
    return (
        <Button
            variant="outlined"
            {...props}
        >
            More
        </Button>
    );
}
