import React, { useState } from "react";

import { Box, Divider, Paper } from "@mui/material";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LoadingIndicator from "../layout/loading/LoadingIndicator";
import useWindowSize from "../../lib/data/useWindowSize";
import dataStore from "../../store/store";
import useData from "../../store/useData";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Title, Tooltip, Legend);

const top = "top";

const powerChartOptions = {
    // responsive: true,
    // maintainAspectRatio: true,
    aspectRatio: 2.58,

    plugins: {
        legend: {
            position: top,
            labels: {
                font: {
                    size: 14,
                },
                color: "#fff",
            },
        },
    },
    animations: false,
    scales: {
        x: {
            ticks: {
                display: true,
            },

            grid: {
                drawBorder: false,
                color: "#000",
            },
        },
        y: {
            title: {
                display: true,
                text: "Power (kW)",
                font: {
                    size: 16,
                    weight: "bold",
                },
                color: "#fff",
            },
            grid: {
                drawBorder: false,
                color: "#000",
            },
        },
    },
};

const frequencyChartOptions = {
    ...powerChartOptions,
    ...{
        plugins: {
            legend: {
                display: false,
            },
        },
    },
    ...{
        scales: {
            x: {
                ticks: {
                    display: true,
                },
                grid: {
                    drawBorder: false,
                    color: "#000",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Frequency (Hz)",
                    font: {
                        size: 16,
                        weight: "bold",
                    },
                    color: "#fff",
                },
                grid: {
                    drawBorder: false,
                    color: "#000",
                },
            },
        },
    },
};

/**
 * Convert numeric seconds to HH:MM:SS string
 *
 * @param {number} seconds
 * @returns HH:MM:SS
 */
const clockString = (seconds) => {
    // In Python: divmod(x,y) -> (quotient, remainder)
    seconds = seconds % (24 * 3600);
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = Math.round(seconds % 60);
    return String(
        String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0")
    );
};

export default function PowerTrendScreen() {
    // @todo: make configurable, in case iPads suffer performance
    const plotNthItem = 1;

    const simEnvironmentData = useData().simEnvironmentHistory(); //  dataStore.use.simEnvironmentHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [simNonce, setSimNonce] = useState(0);

    const { windowWidth, windowHeight } = useWindowSize();
    let idealAspectRatio = 2 * ((windowWidth - 60) / (windowHeight - 20));
    // if (windowWidth < 600) {
    //     idealAspectRatio = 1;
    // }
    // console.log(idealAspectRatio, windowWidth, windowHeight);
    frequencyChartOptions.aspectRatio = idealAspectRatio;
    powerChartOptions.aspectRatio = idealAspectRatio;

    const numberOrNull = (possibleNumber) => {
        const nullValue = null;
        if (possibleNumber === undefined || possibleNumber === null) {
            return nullValue;
        }
        if (isNaN(possibleNumber)) {
            return nullValue;
        }
        return possibleNumber;
    };

    const loadData = [];
    const generationData = [];
    const frequencyData = [];
    const timeLabels = [];

    let timeSet = false;

    let [timeBaseSeconds, timeBaseMinutes, timeBaseHours] = [0, 0, 0];

    const baseTime = new Date(2022, 1, 1, 0, 0, 0, 0);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    const nthLabel = simEnvironmentData.length > 20 ? 4 : 1;

    simEnvironmentData?.map((value, index) => {
        let timeLabel = " ";

        if (value.time && (nthLabel === 1 || index % nthLabel === 0)) {
            timeLabel = clockString(value.time);
        }

        frequencyData.push(numberOrNull(value.frequency));
        loadData.push(numberOrNull(value.net_load));
        generationData.push(numberOrNull(value.net_gen));
        timeLabels.push(timeLabel);
    });

    const powerChartData = {
        labels: timeLabels,
        datasets: [
            {
                id: 1,
                label: "Generation",
                data: generationData,
                borderColor: "rgb(106, 194, 79)",
                backgroundColor: "rgba(106, 194, 79, 1)",
                borderWidth: 1,
                radius: 1,
            },
            {
                id: 2,
                label: "Load",
                data: loadData,
                borderColor: "rgb(255, 99, 0)",
                backgroundColor: "rgba(255, 99, 0, 1)",
                borderWidth: 1,
                radius: 1,
            },
        ],
    };

    const frequencyChartData = {
        labels: timeLabels,
        datasets: [
            {
                id: 1,
                label: "Frequency",
                data: frequencyData,
                borderColor: "rgb(205, 41, 227)",
                backgroundColor: "rgba(205, 41, 227, 0.5)",
                borderWidth: 1,
                radius: 1,
            },
        ],
    };

    return (
        <Box>
            <Paper>
                <Line
                    data={powerChartData}
                    options={powerChartOptions}
                    datasetIdKey="id"
                    className="bg-grey-dark rounded-md"
                    style={{ width: "100%" }}
                />
            </Paper>
            <Divider variant="middle" sx={{ my: 1 }} />
            <Paper>
                <Line
                    data={frequencyChartData}
                    options={frequencyChartOptions}
                    datasetIdKey="id"
                    className="bg-grey-dark rounded-md"
                />
            </Paper>
        </Box>
    );
}
