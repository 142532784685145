import { ErrorOutline as IconError } from "@mui/icons-material";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Slide, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuItemTransition from "./MenuItemTransition";

export default function ErrorStatus() {
    const isError = false;

    const navigation = useNavigate();

    const clickErrorLink = () => {
        navigation("/error");
    };

    return (
        <Slide in={isError} appear={false} direction="right">
            <ListItem
                disablePadding
                sx={{
                    height: isError ? "40px" : 0,
                }}
            >
                <ListItemButton onClick={clickErrorLink}>
                    <ListItemIcon>
                        <IconError color="warning" titleAccess="Simulation Not Running" sx={{ opacity: 0.5 }} />
                    </ListItemIcon>
                    <MenuItemTransition>
                        <ListItemText
                            sx={{
                                transition: "300ms ease-in-out",
                                overflow: "hidden",
                                flexWrap: "nowrap",
                                flexShrink: "0",
                            }}
                        >
                            <Typography>Error</Typography>
                        </ListItemText>
                    </MenuItemTransition>
                </ListItemButton>
            </ListItem>
        </Slide>
    );
}
