import { TextField } from "@mui/material";
import _has from "lodash/has";
import FormControl from "../layout/components/FormControl";

export default function NodePropertyInput(props) {
    const placeholder = props?.placeholder ?? "Enter value...";
    let inputProps = {};
    if (props.type && props.type === "number") {
        inputProps = {
            inputMode: "numeric",
            pattern: "[0-9.]*",
        };
    }
    const propsField = {
        ...props,
        placeholder: placeholder,
        inputProps: inputProps,
    };

    delete propsField.value;
    delete propsField.type;

    const handleOnBlur = (event) => {
        const target = event.target;
        if (target.inputMode === "numeric") {
            target.value = target.value.replace(/[^0-9\.]/g, '');
            if (!target.value) {
                target.value = 0;
            } target.value = parseFloat(target.value);
        }
    };
    const handleOnFocus = (event) => {
        event.target.select();
    };

    return (
        <FormControl>
            <TextField
                {...propsField}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
            />
        </FormControl>
    );
}
