import { Connector } from "mqtt-react-hooks";
import React, { Fragment, useEffect } from "react";
import MqttHelpers from "../../lib/mqtt/PiMqttHelpers";
import dataStore from "../../store/store";
import InitialiseMqtt from "./InitialiseMqtt";
import MqttMessageHandlers from "./MqttMessageHandlers";
import MqttMessageHandler from "./MqttMessageHandler";
import MqttQueueHandler from "./MqttQueueHandler";
import MqttSubscriptionHandler from "./MqttSubscriptionHandler";

export default function MqttProvider(props) {
    // Wait for data to be initialised before connecting
    const isDataInit = dataStore.use.isDataInitialised();
    const isConnected = dataStore.use.isMqttConnected();
    const isSubscribed = dataStore.use.isMqttSubscribed();
    const isMqttInitialised = dataStore.use.isMqttInitialised();

    useEffect(() => {
        if (!isConnected && isSubscribed) {
            dataStore.set.isMqttSubscribed(false);
        }
    }, [isConnected]);

    if (!isDataInit) {
        return <Fragment>{props.children}</Fragment>;
    }

    return (
        <Fragment>
            <Connector options={dataStore.get.mqttOptions()}>
                <InitialiseMqtt>
                    {isConnected && (
                        <MqttQueueHandler>
                            <MqttSubscriptionHandler>
                                {isSubscribed && (
                                    <MqttMessageHandler>
                                        {isMqttInitialised && (
                                            <MqttMessageHandlers>{props.children}</MqttMessageHandlers>
                                        )}
                                        {!isMqttInitialised && props.children}
                                    </MqttMessageHandler>
                                )}
                                {!isSubscribed && props.children}
                            </MqttSubscriptionHandler>
                        </MqttQueueHandler>
                    )}
                    {!isConnected && props.children}
                </InitialiseMqtt>
            </Connector>
        </Fragment>
    );
}
