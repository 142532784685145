import { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from "chart.js";

import "chartjs-plugin-dragdata";

const bgColourPlugin = {
    id: "customCanvasBackgroundColor",
    beforeDraw: (chart, args, options) => {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = options.color || "transparent";
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    },
    onUpdate: (mode) => {
        console.log("UPDATE");
    },
};

const loadProfileTimes = [...Array(24).keys()].map((value) => {
    return String(value).padStart(2, "0") + ":00";
});

export default function LoadProfileGraph(props) {
    const { loadProfile, changeHandler } = props;

    const handleLoadProfileChange = (index, value) => {
        console.log("handling change", index, value);
        changeHandler(index, value);
    };

    const loadProfileChartData = {
        labels: loadProfileTimes,
        datasets: [
            {
                label: "Load (kW)",
                data: [1, 2, 3],
                fill: true,
                tension: 0.4,
                borderWidth: 1,
                pointHitRadius: 25,
                borderColor: "rgb(255, 99, 0)",
                backgroundColor: "rgba(255, 99, 0, 1)",
            },
        ],
    };

    const loadProfileChartOptions = {
        scales: {
            y: {
                min: 0,
                max: 25,
            },
            x: {
                title: {
                    display: true,
                    text: "Time",
                    font: {
                        size: 16,
                        weight: "bold",
                    },
                    color: "#000",
                },
            },
        },
        onHover: function (e) {
            const point = e.chart.getElementsAtEventForMode(e, "nearest", { intersect: true }, false);
            if (point.length) e.native.target.style.cursor = "grab";
            else e.native.target.style.cursor = "default";
        },
        animations: false,
        plugins: {
            dragData: {
                round: 1,
                showTooltip: true,
                onDragStart: function (e, datasetIndex, index, value) {
                    // console.log(e)
                },
                onDrag: function (e, datasetIndex, index, value) {
                    e.target.style.cursor = "grabbing";
                    // console.log(e, datasetIndex, index, value)
                },
                onDragEnd: function (e, datasetIndex, index, value) {
                    e.target.style.cursor = "default";
                    handleLoadProfileChange(index, value);
                },
            },
            customCanvasBackgroundColor: {
                color: "white",
            },
        },
    };

    return (
        <Line
            data={loadProfileChartData}
            options={loadProfileChartOptions}
            style={{ width: "100%" }}
            plugins={[bgColourPlugin]}
        />
    );
}
