import { Backdrop, Box, LinearProgress, Typography } from "@mui/material";

export default function LoadingBackdrop(props) {
    return (
        <Backdrop open={props.open} sx={{ zIndex: 1500 }}>
            <Box
                sx={{
                    position: "absolute",
                    minWidth: "100vw",
                    bottom: 0,
                    zIndex: 1501,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            color: (theme) => theme.palette.primary.main,
                        }}
                    >
                        {props.text ?? "Loading..."}
                    </Typography>
                </Box>
                <LinearProgress />
            </Box>
        </Backdrop>
    );
}
