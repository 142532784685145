import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledEnvSlider = styled(Slider)(({ usecolour }) => ({
    padding: "2px 2px 2px 0",
    height: 48,
    color: usecolour ? usecolour : "#000000",
    borderRadius: 0,

    "& .MuiSlider-thumb": {
        display: "none",
    },
}));

export default function EnvSlider(props) {
    return (
        <Box>
            <StyledEnvSlider {...props} />
        </Box>
    );
}
