import MuiFormControl from "@mui/material/FormControl";

export default function FormControl({ children }) {
    return (
        <MuiFormControl
            fullWidth
            variant="outlined"
            sx={{
                ".MuiOutlinedInput": {
                    "input": {
                        border: "1px solid #666",
                    }
                },
            }}
        >
            {children}
        </MuiFormControl>
    );
}
