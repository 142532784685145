import { IClientOptions } from "mqtt/types/lib/client-options";

/**
 * This is a static helper class for keeping interactions with MQTT consistent and (hopefully) accomodating future changes.
 */
enum MqttTopic {
    UNKNOWN = 0,
    BROADCAST = 1,
    SIM_RESPONSE = 2,
    ENVIRONMENT = 3,
    GUI_EVENT = 4,
    POLL = 5,
    CONFIG = 6,
}

enum MqttQos {
    DEFAULT = 0,
    ALMONST_ONCE = 0,
    ATLEAST_ONCE = 1,
    EXACTLY_ONCE = 2,
}

export default class MqttHelpers {
    private static clientOptions: IClientOptions = {
        port: process.env.REACT_APP_MQTT_PORT
            ? parseInt(process.env.REACT_APP_MQTT_PORT)
            : undefined,
        hostname: process.env.REACT_APP_MQTT_PORT
            ? process.env.REACT_APP_MQTT_HOST
            : undefined,
        keepalive: process.env.REACT_APP_MQTT_KEEPALIVE
            ? parseInt(process.env.REACT_APP_MQTT_KEEPALIVE)
            : undefined,
        username: process.env.REACT_APP_MQTT_USERNAME
            ? process.env.REACT_APP_MQTT_USERNAME
            : undefined,
        password: process.env.REACT_APP_MQTT_PASSWORD
            ? process.env.REACT_APP_MQTT_PASSWORD
            : undefined,
        clientId: process.env.REACT_APP_MQTT_CLIENTID
            ? process.env.REACT_APP_MQTT_CLIENTID
            : undefined,
    };

    private static mqttTopics: Map<MqttTopic, string> = new Map([
        [MqttTopic.BROADCAST, "all/#"],
        [MqttTopic.SIM_RESPONSE, "gui/info/#"],
        [MqttTopic.ENVIRONMENT, "pi/simenvironment/#"],
        [MqttTopic.GUI_EVENT, "gui/event/#"],
        [MqttTopic.POLL, "pi/poll"],
        [MqttTopic.CONFIG, "pi/config/#"],
    ]);

    static getClientOptions = (): IClientOptions => {
        return this.clientOptions;
    };

    /**
     * Get enum from topic string
     *
     * @param topic
     * @param matchStart If false, matches the start - e.g. `gui/event/sim_event` => `gui/event/#` => `GUI_EVENT`
     */
    static getTopicEnum = (topic: string, matchStart = false) => {
        let topicValue = MqttTopic.UNKNOWN;
        this.mqttTopics.forEach((value, key) => {
            if (value === topic) {
                topicValue = key;
                return;
            }
            const checkVal = value.replace("#", "");
            if (matchStart && topic.startsWith(checkVal)) {
                topicValue = key;
                return;
            }
        });
        return topicValue ? MqttTopic[topicValue] : MqttTopic.UNKNOWN;
    };

    /**
     * Get topic string from enum
     *
     * @param key
     * @param suffix
     * @returns {string}
     */
    static getTopicString = (
        key: MqttTopic | String,
        suffix?: string
    ): string => {
        if (typeof key === "string") {
            return MqttHelpers.suffixTopic({ topic: key, suffix: suffix });
        }
        const topic: MqttTopic = parseInt(key.toString());
        if (this.mqttTopics.has(topic)) {
            const value = this.mqttTopics.get(topic);
            if (value) {
                return MqttHelpers.suffixTopic({
                    topic: value.toString(),
                    suffix,
                });
            }
        }
        return "";
    };

    static suffixTopic({
        topic,
        suffix,
    }: {
        topic: string;
        suffix: string | undefined;
    }): string {
        if (suffix !== undefined) {
            topic = topic.replace("#", suffix);
        }
        return topic;
    }
}

export { MqttHelpers, MqttQos, MqttTopic };
