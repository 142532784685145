import React, { Fragment } from "react";
import useData from "../../store/useData";
import Card from "../layout/components/Card";
import GridContainer from "../layout/grid/GridContainer";
import GridRow from "../layout/grid/GridRow";
import SectionTitle from "../layout/SectionTitle";
import SimulationBlackouts from "./SimulationBlackouts";
import SimulationControl from "./SimulationControls";
import SimulationRealTimeFactor from "./SimulationRealTimeFactor";
import SimulationStartTime from "./SimulationStartTime";

export default function SimulationScreen() {
    const isConnected = useData().isMqttConnected();
    const isRunning = useData().isSimRunning();

    return (
        // @todo: look at react-transition-group or similar for intended animations (CSS doesn't animate)
        <Card>
            <GridContainer>
                <GridRow>
                    <SectionTitle>Simulation</SectionTitle>
                </GridRow>
                {isConnected && (
                    <Fragment>
                        <SimulationControl isRunning={isRunning} />
                        <SimulationBlackouts />
                        <SimulationRealTimeFactor />
                        <SimulationStartTime isRunning={isRunning} />
                    </Fragment>
                )}
                {!isConnected && <p>Waiting for connection...</p>}
            </GridContainer>
        </Card>
    );
}
