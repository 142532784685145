import batteryIcon from "../../images/grid/battery.png";
import powerStationIcon from "../../images/grid/power-station.png";
import officeIcon from "../../images/grid/office.png";
import houseIcon from "../../images/grid/house.png";
import gridIcon from "../../images/grid/grid.png";
import factoryIcon from "../../images/grid/factory.png";
import buildingIcon from "../../images/grid/building.png";

const icons = ["building", "house", "power_station", "battery", "grid", "office"];

const iconsByNodeType = new Map([
    ["custom", "building"],
    ["house", "house"],
    ["generator", "power_station"],
    ["storage", "battery"],
    ["grid", "grid"],
    ["office", "office"],
    ["factory", "factory"],
]);

function getNodeIconNameByType(nodeType) {
    return iconsByNodeType.has(nodeType) ? iconsByNodeType.get(nodeType) : null;
}

function getNodeIconImage(iconName) {
    let icon = null;
    switch (iconName) {
        case "building":
            icon = buildingIcon;
            break;
        case "house":
            icon = houseIcon;
            break;
        case "factory":
            icon = factoryIcon;
            break;
        case "power_station":
        case "generator":
            icon = powerStationIcon;
            break;
        case "battery":
            icon = batteryIcon;
            break;
        case "grid":
            icon = gridIcon;
            break;
        case "office":
            office = officeIcon;
            break;
    }

    return icon;
}

export { getNodeIconNameByType, getNodeIconImage };
export default getNodeIconNameByType;
