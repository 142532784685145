import { SensorsOffRounded as IconDisconnected, SensorsRounded as IconConnected } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText, Slide, Typography } from "@mui/material";
import React from "react";
import dataStore from "../../../store/store";
import MenuItemTransition from "./MenuItemTransition";

export default function ConnectionStatus(props: any) {
    const isConnected = dataStore.use.isMqttConnected();
    const isInitialised = dataStore.use.isInitialised();

    const icon = isConnected ? (
        <IconConnected color="success" titleAccess="Pi Connected" />
    ) : (
        <IconDisconnected color="warning" titleAccess="Pi Not Connected" sx={{ opacity: 0.5 }} className="pulser" />
    );

    const text = isConnected ? "Connected" : "Disconnected";
    const colour = isConnected ? "success" : "warning";

    return (
        <Slide in={isInitialised} appear={false} direction="right">
            <ListItem
                sx={{
                    height: "40px",
                    overflow: "hidden",
                    flexWrap: "nowrap",
                    flexShrink: "0",
                }}
            >
                <ListItemIcon>{icon}</ListItemIcon>
                <MenuItemTransition in={isInitialised}>
                    <ListItemText
                        sx={{
                            overflow: "hidden",
                            flexWrap: "nowrap",
                            flexShrink: "0",
                        }}
                    >
                        <Typography>{text}</Typography>
                    </ListItemText>
                </MenuItemTransition>
            </ListItem>
        </Slide>
    );
}
