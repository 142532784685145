import { Box, List } from "@mui/material";
import { Fragment } from "react";
import BlackoutStatus from "./BlackoutStatus";
import ConnectionStatus from "./ConnectionStatus";
import ErrorStatus from "./ErrorStatus";
import SimulationStatus from "./SimulationStatus";

export default function MenuWidgets(props) {
    return (
        <Box>
            <List sx={{ textAlign: "center" }}>
                <ConnectionStatus {...props} />
                <SimulationStatus {...props} />
                <BlackoutStatus {...props} />
                <ErrorStatus {...props} />
            </List>
        </Box>
    );
}
