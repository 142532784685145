import clone from "lodash/clone";

export const useMessageHandlerChecksum = (topic, handlerFunction) => {
    // Schnaaader's implementation courtesy of:
    //  https://stackoverflow.com/questions/811195/fast-open-source-checksum-for-small-strings
    function checksum(s) {
        var chk = 0x12345678;
        var len = s.length;
        for (var i = 0; i < len; i++) {
            chk += s.charCodeAt(i) * (i + 1);
        }

        return (chk & 0xffffffff).toString(16);
    }

    return checksum(
        topic +
            "_" +
            handlerFunction.name +
            "_" +
            handlerFunction.toString().length.toString()
    );
};
