import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../layout/loading/LoadingIndicator";

function NodeEditorScreen(props) {
    const nodesContext = {};

    const params = useParams();
    const { activeNode, handleSave, handleCancel } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [saveNode, setSaveNode] = useState(null);

    const [node, setNode] = useState(null);

    useEffect(() => {}, [saveNode]);

    useEffect(() => {
        if (!activeNode) {
            // navigate("/nodes");
        }
        const fetchNode = nodesContext.nodes.get(nodeId);
        setNode({ ...fetchNode });
        setIsLoading(false);
    }, []);

    if (!node || isLoading) {
        return <LoadingIndicator />;
    }

    return <Fragment></Fragment>;
}

export default NodeEditorScreen;
