import React from "react";
import GridNodes from "./GridNodes";

export default function GridViewScreen() {
    return (
        <div>
            <GridNodes />
        </div>
    );
}
