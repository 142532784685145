import { Fragment } from "react";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";
import FormControl from "../layout/components/FormControl";
import { MenuItem, Select } from "@mui/material";
import useData from "../../store/useData";
import dataStore, { store } from "../../store/store";

const startTimes = [...Array(24).keys()].map((value) => {
    return String(value).padStart(2, "0") + ":00";
});

export default function SimulationStartTime(props) {
    const { isRunning } = props;
    const simStartTime = useData().simStartTime();

    const handleChangeStartTime = (e) => {
        const { value } = e.target;
        dataStore.set.simStartTime(value);
    };

    return (
        <Fragment>
            <GridItem1>Simulation Start Time</GridItem1>
            <GridItem2>
                <FormControl>
                    <Select name="sim_start_time" onChange={handleChangeStartTime} value={simStartTime} disabled={isRunning}>
                        {startTimes.map((value, index) => {
                            return (
                                <MenuItem key={index} value={value}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </GridItem2>
            {/* {!isDynamic && (
                <Fragment>
                    <GridItem1>
                        <TextField
                            name="real_time_factor"
                            type="number"
                            min="0.01"
                            max="20.00"
                            step="0.1"
                            onChange={changeValue}
                            value={userRealTimeFactor}
                        />
                    </GridItem1>
                    <GridItem2>
                        <ButtonSave onClick={saveRtf} />
                    </GridItem2>
                </Fragment>
            )} */}
        </Fragment>
    );
}
