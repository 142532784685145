import { createStore } from "@udecode/zustood";
import { mapValuesKey } from "@udecode/zustood";

import { IUseSubscription } from "mqtt-react-hooks";

export interface MqttMessage {
    topic: string;
    message: string;
}

const emptySubscription: IUseSubscription = { topic: "", connectionStatus: "" };

const dataStore = createStore("data")({
    debug: false,

    isInitialised: false,

    isConfigLoaded: false,
    config: new Map(),

    isDataFetched: false,
    isInitialDataProcessed: false,
    isDataInitialised: false,
    isPresetsFetched: false,
    isEnvInitialised: false,
    isMqttInitialised: false,
    isMqttConnected: false,
    isMqttSubscribed: false,
    isMqttMessageHandlersAdded: false,

    isSimRunning: false,
    isSimStarting: false,
    isSimStopping: false,
    requestSimStart: false,
    requestSimStop: false,
    requestSimToggle: false,

    simStartTime: "07:00",

    isError: false,
    isDrawerOpen: true,

    isSmallWindow: false,

    realTimeFactor: 1.0,
    isDynamicTimeStep: true,

    nodeConnections: [],
    nodeConnectionsConfig: new Map(),
    // gui/presets/details -> House 3,Office,House 2,House 1,House 4,Factory
    presets: [],

    nodes: new Map<string, object>(),

    simEnvironmentMode: "",
    simEnvironment: new Map(),
    simEnvironmentHistory: [],

    simTime: 0,
    simTimeClock: "12:00",

    environmentConfig: new Map(),
    options: new Map(),
    system: new Map(),
    topology: [],
    gridMap: [],

    blackouts: false,
    // Array of nodes that are blacked out; also "all"
    blackoutsActive: new Map(),
    isBlackout: false,

    errors: [],

    apiBaseUrl: "",

    mqttSubscriptionHandler: emptySubscription,

    messageHandlers: [],
    messageHandlerChecksums: [],

    addMqttMessageHandler: async (mqttMessage: MqttMessage) => {
        console.error("MQTT handler not ready.");
        throw new Error("MQTT handler not ready.");
    },

    enqueueMqttMessage: (mqttMessage: MqttMessage) => {
        console.error("MQTT handler not ready.");
        throw new Error("MQTT handler not ready.");
    },

    mqttOptions: {
        port: undefined,
        hostname: undefined,
        keepalive: undefined,
        username: undefined,
        password: undefined,
        clientId: undefined,
    },
});

export const rootStore = {
    data: dataStore,
};

// Global hook selectors
export const useStore = () => mapValuesKey("use", rootStore);

// Global tracked hook selectors
export const useTrackedStore = () => mapValuesKey("useTracked", rootStore);

// Global getter selectors
export const store = mapValuesKey("get", rootStore);

// Global actions
export const actions = mapValuesKey("set", rootStore);

export default dataStore;
