import { Fragment, useState } from "react";
import NumberFormat from "react-number-format";
import GridContainer from "../layout/grid/GridContainer";
import GridItem from "../layout/grid/GridItem";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";
import EnvSlider from "./EnvSlider";

export default function EnvironmentSandboxSection(props) {
    const {
        name,
        label,
        colour,
        step,
        dataValue,
        dataValueChange,
        min,
        max,
        unitHtml,
    } = props;

    /**
     * @todo: functionality for environment property to be clicked, display a modal with text-based input, so very specific values can be set
     */
    const [envModal, setEnvModal] = useState({
        show: false,
        name: "",
    });

    const modifyEnvValue = (dataName) => {
        if (envModal.show) {
            return;
        }
        setEnvModal({
            show: true,
            name: dataName,
        });
    };

    return (
        <Fragment>
            <GridItem1>{label}</GridItem1>
            <GridItem2>
                <GridContainer>
                    <GridItem color={colour} xs={6} md={3}>
                        {step === 1 && (
                            <NumberFormat
                                value={dataValue}
                                displayType={"text"}
                            />
                        )}
                        {step !== 1 && (
                            <NumberFormat
                                value={dataValue}
                                displayType={"text"}
                                decimalScale={1}
                                fixedDecimalScale={true}
                            />
                        )}{" "}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: unitHtml,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6} md={9}>
                        <EnvSlider
                            usecolour={colour || ""}
                            aria-label={label || "Slider"}
                            value={dataValue || null}
                            name={name}
                            step={step || 1}
                            min={min || 0}
                            max={max || 100}
                            onChange={dataValueChange}
                            valueLabelDisplay="auto"
                        />
                    </GridItem>
                </GridContainer>
            </GridItem2>
        </Fragment>
    );
}
