export const defaultConfig = {
    MQTT_PORT: 9001,
    MQTT_HOSTNAME: "microgrid",
    MQTT_KEEPALIVE: 60,
    MQTT_USERNAME: "",
    MQTT_PASSWORD: "",
    API_BASE_URL: "http://microgrid:6150/v1.0/",
    DEBUG_MODE: false,
};

export const dynamicConfigUrl = "config.json";
