import React, { Fragment } from "react";
import DataHandler from "./DataHandler";
import dataStore from "../../store/store";
import InitialiseData from "./InitialiseData";
import SimEnvironmentData from "./SimEnvironmentData";
import NodeData from "./NodeData";
import TopologyData from "./TopologyData";

/**
 * @param {{ children: any | undefined; }} props
 */
export default function DataProvider(props) {
    const isInitialised = dataStore.use.isInitialised();
    const isDataInitialised = dataStore.use.isDataInitialised();

    return (
        <Fragment>
            {!isDataInitialised && <InitialiseData />}
            {isInitialised && (
                <Fragment>
                    <SimEnvironmentData />
                    <NodeData />
                    <TopologyData />
                </Fragment>
            )}
            <DataHandler>{props.children}</DataHandler>
        </Fragment>
    );
}
