import { Box } from "@mui/system";
import { useState } from "react";
import Menu from "./menu/Menu";

export default function TopBar(props) {
    return (
        <Box sx={{  }}>
            <Menu {...props} />
        </Box>
    );
}
