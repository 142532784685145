import PowerTrendsIcon from "@mui/icons-material/AssessmentRounded";
import NodeManagerIcon from "@mui/icons-material/HomeWorkRounded";
import GridIcon from "@mui/icons-material/LanRounded";
import SimulationIcon from "@mui/icons-material/SettingsApplicationsRounded";
import EnvironmentIcon from "@mui/icons-material/SolarPowerRounded";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";

const menuItems = [
    {
        text: "Grid",
        link: "grid",
        icon: <GridIcon />,
    },
    {
        text: "Power Trends",
        link: "power",
        icon: <PowerTrendsIcon />,
    },
    {
        text: "Environment",
        link: "environment",
        icon: <EnvironmentIcon />,
    },
    {
        text: "Node Manager",
        link: "nodes",
        icon: <NodeManagerIcon />,
    },
    {
        text: "Simulation",
        link: "simulation",
        icon: <SimulationIcon />,
    },
    {
        text: "Demo",
        link: "demo",
        icon: <SmartDisplayIcon />,
    },
];

export default menuItems;
