import React, { Fragment, useEffect, useState } from "react";
import dataStore from "../../store/store";

export default function DataHandler(props) {
    const isInitialised = dataStore.use.isInitialised();

    const [isInitialiseDataFinished, setIsInitialiseDataFinished] = useState(false);
    const [data, setData] = useState({
        nodeConnectionsConfigData: {},
        nodeConnectionsData: [],
        simEnvironmentHistoryData: [],
    });

    useEffect(() => {
        if (!isInitialiseDataFinished) {
            return;
        }
        dataStore.set.isDataInitialised(true);
    }, [isInitialiseDataFinished]);

    useEffect(() => {
        const { nodeConnectionsConfigData, nodeConnectionsData, simEnvironmentHistoryData } = data;
        dataStore.set.nodeConnections(nodeConnectionsData);
        dataStore.set.nodeConnectionsConfig(nodeConnectionsConfigData);
        dataStore.set.simEnvironmentHistory(simEnvironmentHistoryData);
    }, [data]);

    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
}
