import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import FormControl from "../layout/components/FormControl";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";
import GridContainer from "../layout/grid/GridContainer";
import useData from "../../store/useData";

export default function EnvironmentScenario(props) {
    const environmentConfig = useData().environmentConfig();

    const { environmentConfigChangeHandler } = props;
    const { irradiance_profile, temperature_profile, windspeed_profile } = environmentConfig;

    const irradianceProfiles = ["Constant", "Summer", "Winter"];
    const temperatureProfiles = [...irradianceProfiles];
    const windSpeedProfiles = ["Constant", "Gusty"];

    const [irradianceProfile, setIrradianceProfile] = useState(irradiance_profile);
    const [temperatureProfile, setTemperatureProfile] = useState(temperature_profile);
    const [windSpeedProfile, setWindSpeedProfile] = useState(windspeed_profile);

    const handleChangeIrradianceProfile = ({ target }) => {
        const newProfile = irradianceProfiles.filter((value, index) => {
            return value.toLowerCase() === target.value.toLowerCase();
        });
        if (!newProfile || newProfile.length < 1) {
            return;
        }
        setIrradianceProfile(newProfile[0]);
    };

    const handleChangeTemperatureProfile = ({ target }) => {
        const newProfile = temperatureProfiles.filter((value, index) => {
            return value.toLowerCase() === target.value.toLowerCase();
        });
        if (!newProfile || newProfile.length < 1) {
            return;
        }
        setTemperatureProfile(newProfile[0]);
    };

    const hanbdleChangeWindSpeedProfile = ({ target }) => {
        const newProfile = windSpeedProfiles.filter((value, index) => {
            return value.toLowerCase() === target.value.toLowerCase();
        });
        if (!newProfile || newProfile.length < 1) {
            return;
        }
        setWindSpeedProfile(newProfile[0]);
    };

    /**
     * Update config on change
     */
    useEffect(() => {
        let updateConfig = [];
        if (irradianceProfile !== irradiance_profile) {
            updateConfig.push({ irradiance_profile: irradianceProfile });
        }
        if (temperatureProfile !== temperature_profile) {
            updateConfig.push({ temperature_profile: temperatureProfile });
        }
        if (windSpeedProfile !== windspeed_profile) {
            updateConfig.push({ windspeed_profile: windSpeedProfile });
        }
        if (updateConfig.length < 1) {
            return;
        }
        environmentConfigChangeHandler(updateConfig.pop());
    }, [irradianceProfile, temperatureProfile, windSpeedProfile]);

    if (!irradianceProfile || !temperatureProfile || !windSpeedProfile) {
        return null;
    }

    return (
        <GridContainer>
            <GridItem1>Irradiance Profile</GridItem1>
            <GridItem2>
                <FormControl>
                    <Select
                        name="irradiance_profile"
                        onChange={handleChangeIrradianceProfile}
                        value={irradianceProfile}
                    >
                        {irradianceProfiles.map((value, index) => {
                            return (
                                <MenuItem key={index} value={value}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </GridItem2>
            <GridItem1>Temperature Profile</GridItem1>
            <GridItem2>
                <FormControl>
                    <Select
                        name="temperature_profile"
                        onChange={handleChangeTemperatureProfile}
                        value={temperatureProfile}
                    >
                        {temperatureProfiles.map((value, index) => {
                            return (
                                <MenuItem key={index} value={value}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </GridItem2>
            <GridItem1>Wind Speed Profile</GridItem1>
            <GridItem2>
                <FormControl>
                    <Select name="wind_speed_profile" onChange={hanbdleChangeWindSpeedProfile} value={windSpeedProfile}>
                        {windSpeedProfiles.map((value, index) => {
                            return (
                                <MenuItem key={index} value={value}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </GridItem2>
        </GridContainer>
    );
}
