import Icon from "@mui/icons-material/Menu";
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import MenuItemTransition from "./MenuItemTransition";
import muLogo from "../../../images/mu-red.png";
import { layoutOptions } from "../Layout";
import SectionTitle from "../SectionTitle";

export default function MenuIcon(props) {
    return (
        <ListItem disablePadding>
            <ListItemButton
                onClick={props.drawerToggle}
                disabled={props.disabled}
            >
                <ListItemIcon>
                    <Icon color="primary" />
                </ListItemIcon>
                <MenuItemTransition in={props.drawerOpen}>
                    <ListItemText
                        sx={{
                            overflow: "hidden",
                            flexWrap: "nowrap",
                            flexShrink: "0",
                        }}
                    >
                        <img
                            src={muLogo}
                            alt="Murdoch University"
                            style={{
                                width: "auto",
                                height: "11px",
                                transition: layoutOptions.drawerTransition,
                            }}
                        />
                        <SectionTitle
                            color="primary"
                            fontWeight="bold"
                            sx={{
                                display: "inline-flex",
                            }}
                        >
                            grid
                        </SectionTitle>
                    </ListItemText>
                </MenuItemTransition>
            </ListItemButton>
        </ListItem>
    );
}
