import { Box, CssBaseline, Skeleton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import theme from "../../lib/layout/theme";
import ErrorModal from "./ErrorModal";
import LoadingBackdrop from "./LoadingBackdrop";
import TopBar from "./TopBar";
import dataStore from "../../store/store";
import useWindowSize from "../../lib/data/useWindowSize";

export const layoutOptions = {
    drawerZIndex: theme.zIndex.drawer,
    drawerWidthOpen: 220,
    drawerWidthClosed: 60,
    drawerTransition: "200ms ease-in-out",
};

export default function Layout() {
    const { windowWidth, windowHeight } = useWindowSize();

    const isSmallWindow = dataStore.get.isSmallWindow();
    const isInitialised = dataStore.use.isInitialised();
    const isError = dataStore.use.isError();
    const isDrawerOpen = dataStore.use.isDrawerOpen();

    const toggleDrawerOpen = () => {
        const isOpen = !dataStore.get.isDrawerOpen();
        dataStore.set.isDrawerOpen(isOpen);
        localStorage.setItem("drawer-open", isOpen);
    };
    // const { isInitialised, isError, isDrawerOpen, toggleDrawerOpen } = data;
    const debug = false;
    const showErrorModal = !isInitialised && isError;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <TopBar drawerOpen={isDrawerOpen} drawerToggle={toggleDrawerOpen} />
            <Box
                sx={{
                    marginLeft:
                        (isDrawerOpen && !isSmallWindow
                            ? layoutOptions.drawerWidthOpen
                            : layoutOptions.drawerWidthClosed) + "px",
                    transition: "150ms ease-in-out",
                    overflow: "auto",
                    p: isInitialised ? (isSmallWindow ? "1px" : "16px") : "0",
                }}
            >
                {isInitialised && <Outlet />}
                {!isInitialised && <Skeleton variant="rectangular" height="100vh" />}
            </Box>
            {showErrorModal && <ErrorModal />}
            <LoadingBackdrop text="Initialising..." open={!isInitialised} />
        </ThemeProvider>
    );
}
