import { Fragment, useEffect, useState } from "react";
import dataStore, { useStore, actions } from "../../store/store";
import { has } from "lodash";

export default function SimEnvironmentData(props) {
    const addMessageHandler = dataStore.get.addMqttMessageHandler();
    const [requestSimStart, requestSimStop, requestSimToggle] = [
        dataStore.use.requestSimStart(),
        dataStore.use.requestSimStop(),
        dataStore.use.requestSimToggle(),
    ];
    const enqueueMessage = dataStore.get.enqueueMqttMessage();

    const [simDataItem, setSimDataItem] = useState({});

    /**
     * Respond to simToggle requests
     */
    useEffect(() => {
        if (!requestSimToggle) {
            return;
        }
        if (dataStore.get.isSimRunning()) {
            actions.data.requestSimStop(true);
        } else {
            actions.data.requestSimStart(true);
        }
    }, [requestSimToggle]);

    /**
     * Respond to simStart/simStop requests
     */
    useEffect(() => {
        if (!requestSimStart && !requestSimStop) {
            return;
        }
        const startSim = requestSimStart && !requestSimStop;
        const stopSim = requestSimStop;

        // Setup sim time first
        const simStartTime = dataStore.get.simStartTime().split(":");
        if (simStartTime.length === 2) {
            const simStartHour = parseInt(simStartTime[0]);
            if (!isNaN(simStartHour)) {
                enqueueMessage({
                    topic: "gui/event/sim_start_time",
                    message: simStartHour * 3600,
                });
            }
        }

        enqueueMessage({
            topic: "gui/event/sim_active",
            message: stopSim ? "false" : "true",
        });
        actions.data.isSimStopping(stopSim);
        actions.data.isSimStarting(startSim);

        if (stopSim) {
            actions.data.requestSimStart(false);
            actions.data.requestSimStop(false);
        } else if (startSim) {
            actions.data.requestSimStart(false);
        }
    }, [requestSimStart, requestSimStop]);

    /**
     * Handle pi/simenvironment MQTT message
     *
     * @param {string} topic
     * @param {string} message
     */
    const handleSimEnvironmentMessage = (topic, message) => {
        if (!dataStore.get.isSimStopping() && !dataStore.get.isSimRunning()) {
            dataStore.set.isSimRunning(true);
        }
        const topicSplit = topic.split("/");
        const topicChild = topicSplit.pop();
        const topicFormat = topicChild
            .replace(/sim(.+)/g, "$1")
            .replace(/^([A-Z])/, (value) => value.toLowerCase())
            .replace(/([A-Z])/g, (value) => "_" + value.toLowerCase());

        const value = isNaN(message) ? message : parseFloat(message);
        const newItem = { [`${topicFormat}`]: value };

        setSimDataItem((s) => {
            return s ? { ...s, ...newItem } : { ...newItem };
        });
    };

    /**
     * Check and pass along dataitem when updated
     */
    useEffect(() => {
        if (!has(simDataItem, "time") || dataStore.get.simEnvironment().time === simDataItem.time) {
            return;
        }
        let history = [...dataStore.get.simEnvironmentHistory()];
        if (history.length >= 50) {
            history = history.slice(history.length - 49);
        }

        if (has(simDataItem, "mode") && dataStore.get.simEnvironmentMode() !== simDataItem.mode) {
            dataStore.set.simEnvironmentMode(simDataItem.mode);
        }
        dataStore.set.simEnvironment(simDataItem);
        dataStore.set.simEnvironmentHistory([...history, simDataItem]);
        setSimDataItem({});
    }, [simDataItem]);

    /**
     * On first render, initialise message handler
     */
    useEffect(() => {
        addMessageHandler({
            topic: "pi/simenvironment/#",
            handlerFunction: handleSimEnvironmentMessage,
        });
    }, []);

    return <Fragment></Fragment>;
}
