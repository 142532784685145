import { Fragment, useEffect, useState } from "react";
import dataStore from "../../store/store";
import { useMessageHandlerChecksum } from "../../lib/mqtt/useMessageHandlerChecksum";
export default function MqttMessageHandler(props) {
    const subscriptionHandler = dataStore.use.mqttSubscriptionHandler();
    const { message: mqttMessage } = subscriptionHandler;

    const messageHandlers = dataStore.get.messageHandlers();
    const messageHandlerChecksums = dataStore.get.messageHandlerChecksums();

    /**
     * Add message handler to context, to be fired when message received for topic
     *
     * @param {{topic: string, handlerFunction: function}} void
     */
    const handleAddMessageHandler = async ({ topic, handlerFunction }) => {
        console.log("Adding handler for " + topic);
        const checksum = useMessageHandlerChecksum(topic, handlerFunction);
        const checksumExists = messageHandlerChecksums.indexOf(checksum);

        if (checksumExists >= 0) {
            return;
        }

        dataStore.set.messageHandlerChecksums([...dataStore.get.messageHandlerChecksums(), checksum]);

        dataStore.set.messageHandlers([...dataStore.get.messageHandlers(), { topic, handlerFunction }]);
    };

    /**
     * Check and fire relavent handler functions for topic
     *
     */
    const handleFireMessageHandlers = async ({ topic, message }) => {
        const topicString = String(topic);
        messageHandlers.forEach((value) => {
            const topicPattern = new RegExp(value.topic.replace("#", "(.+)"), "g");
            if (topicString.match(topicPattern)) {
                const handlerFunction = value.handlerFunction;
                handlerFunction(topicString, message);
            }
        });
    };

    useEffect(() => {
        dataStore.set.addMqttMessageHandler(handleAddMessageHandler);
        dataStore.set.isMqttInitialised(true);
    }, []);

    /**
     * Fire handlers as messages arrive
     */
    useEffect(() => {
        if (!mqttMessage || !mqttMessage.message || !mqttMessage.topic) {
            return;
        }
        const { topic, message } = mqttMessage;
        handleFireMessageHandlers({ topic, message });
    }, [mqttMessage]);

    return <Fragment>{props.children}</Fragment>;
}
