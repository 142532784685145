import { useState, useEffect } from "react";

// Thanks for this example: https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs

function getWindowDimensions() {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    return {
        windowWidth,
        windowHeight,
    };
}

export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowSize(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}
