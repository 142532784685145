import React, { Fragment } from "react";
import dataStore, { actions, MqttMessage } from "../../store/store";
import useData from "../../store/useData";
import Button from "../layout/buttons/Button";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";

export default function SimulationControls(props: any): JSX.Element {
    const debug = dataStore.get.debug();

    // const isRunning = useData().isSimRunning();
    const { isRunning } = props;

    const enqueueMessage = dataStore.get.enqueueMqttMessage();

    const handlerSimulationStart = () => {
        actions.data.requestSimStart(true);
        // const message: MqttMessage = {
        //     topic: "gui/event/sim_active",
        //     message: "true",
        // };
        // enqueueMessage(message);
        // actions.data.isSimStarting(true);
        // actions.data.isSimStopping(false);
    };

    const handlerSimulationStop = () => {
        actions.data.requestSimStop(true);
        // const message: MqttMessage = {
        //     topic: "gui/event/sim_active",
        //     message: "false",
        // };
        // enqueueMessage(message);
        // actions.data.isSimStopping(true);
        // actions.data.isSimStarting(false);
    };

    return (
        <Fragment>
            <GridItem1>Control</GridItem1>
            <GridItem2>
                <Button color="success" onClick={handlerSimulationStart} disabled={isRunning}>
                    Start
                </Button>
                <Button color="warning" onClick={handlerSimulationStop}>
                    Stop
                </Button>
            </GridItem2>
        </Fragment>
    );
}
