import Button from "./Button";

export default function ButtonCancel(props) {
    return (
        <Button
            color="error"
            onClick={props.onClick}
            disabled={props.disabled}
        >
            Cancel
        </Button>
    );
}
