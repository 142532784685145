import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow
} from "@mui/material";
import { Box } from "@mui/system";
import SectionTitle from "../layout/SectionTitle";

export default function ErrorScreen() {
    const errors = [];
    const hasErrors = errors !== undefined && errors.length > 0;
    return (
        <Box>
            <SectionTitle>Errors</SectionTitle>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 640 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hasErrors &&
                            errors.map((value, index) => {
                                const errorDate = new Date(value.date);
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {errorDate.toLocaleDateString()}{" "}
                                            {errorDate.toLocaleTimeString()}
                                        </TableCell>
                                        <TableCell>{value.title}</TableCell>
                                        <TableCell>
                                            <pre>{value.message}</pre>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {!hasErrors && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    No errors logged.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
