import { Fragment, useEffect } from "react";
import useFetchEnvironmentConfigData from "../../lib/data/useFetchEnvironmentConfigData";
import useFetchNodeConnectionsConfigData from "../../lib/data/useFetchNodeConnectionsConfigData";
import useFetchNodesConnectionsData from "../../lib/data/useFetchNodesConnectionsData";
import useFetchSimEnvironmentData from "../../lib/data/useFetchSimEnvironmentData";
import useFetchSimEnvironmentHistoryData from "../../lib/data/useFetchSimEnvironmentHistoryData";
import useFetchSystemTopologyData from "../../lib/data/useFetchSystemTopologyData";
import dataStore from "../../store/store";

export default function FetchData(props) {
    const [isNodeConnectionDataLoaded, nodeConnectionsData] = useFetchNodesConnectionsData();
    const [isNodeConnectionsConfigDataLoaded, nodeConnectionsConfigData] = useFetchNodeConnectionsConfigData();
    const [isSimEnvironmentHistoryDataLoaded, simEnvironmentHistoryData] = useFetchSimEnvironmentHistoryData();
    const [isSystemTopologyDataLoaded, systemTopologyData] = useFetchSystemTopologyData();
    const [isSimEnvironmentDataLoaded, simEnvironmentData] = useFetchSimEnvironmentData();
    const [isEnvironmentConfigDataLoaded, environmentConfigData] = useFetchEnvironmentConfigData();

    const isDataLoaded =
        isNodeConnectionDataLoaded &&
        isNodeConnectionsConfigDataLoaded &&
        isSimEnvironmentHistoryDataLoaded &&
        isSystemTopologyDataLoaded &&
        isSimEnvironmentDataLoaded &&
        isEnvironmentConfigDataLoaded;

    useEffect(() => {
        dataStore.set.nodeConnections(nodeConnectionsData);
    }, [isNodeConnectionDataLoaded]);

    useEffect(() => {
        dataStore.set.nodeConnectionsConfig(nodeConnectionsConfigData);
    }, [isNodeConnectionsConfigDataLoaded]);

    useEffect(() => {
        dataStore.set.simEnvironmentHistory(simEnvironmentHistoryData);
    }, [isSimEnvironmentHistoryDataLoaded]);

    useEffect(() => {
        dataStore.set.topology(systemTopologyData);
    }, [isSystemTopologyDataLoaded]);

    useEffect(() => {
        dataStore.set.simEnvironment(simEnvironmentData);
    }, [isSimEnvironmentDataLoaded]);

    useEffect(() => {
        dataStore.set.environmentConfig(environmentConfigData);
    }, [isEnvironmentConfigDataLoaded]);


    useEffect(() => {
        if (!isDataLoaded) {
            return;
        }
        dataStore.set.isDataFetched(true);
    }, [isDataLoaded]);

    return <Fragment>{props.children}</Fragment>;
}
