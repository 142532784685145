import { Fragment, useEffect, useState } from "react";
import { dynamicConfigUrl } from "../../config/config";
import dataStore from "../../store/store";
import FetchData from "./FetchData";
import InitialiseEnvironment from "./InitialiseEnvironment";
import ProcessInitialData from "./ProcessInitialData";

export default function InitialiseData(props) {
    const isInitialised = dataStore.use.isInitialised();
    const [isConfigFetched, setIsConfigFetched] = useState(false);
    const [configData, setConfigData] = useState({});
    const isConfigLoaded = dataStore.use.isConfigLoaded();
    const isDataInitialised = dataStore.use.isDataInitialised();
    const isEnvInitialised = dataStore.use.isEnvInitialised();
    const isNodeDataProcessed = dataStore.use.isInitialDataProcessed();
    const isDataFetched = dataStore.use.isDataFetched();
    const nodes = dataStore.get.nodes();

    const updateNode = (nodeId, newConfigValue) => {
        if (!nodes.has(nodeId)) {
            nodes.set(nodeId, new Map());
            // Request nick name (reconnect?)
            enqueueMessage({ topic: nodeId, message: "nickName" });
        }
        nodes.set(nodeId, new Map([...nodes.get(nodeId), ...newConfigValue]));
        dataStore.set.nodes(nodes);
    };

    useEffect(() => {
        const isDrawerOpenLocal = localStorage.getItem("drawer-open") === "true";
        if (dataStore.get.isDrawerOpen() !== isDrawerOpenLocal) {
            dataStore.set.isDrawerOpen(isDrawerOpenLocal);
        }
    }, []);

    /**
     *  First, fetch the config - once
     */
    useEffect(async () => {
        setConfigData(
            await fetch(dynamicConfigUrl).then((r) => {
                return r.json();
            })
        );
        setIsConfigFetched(true);
    }, []);

    /**
     * Load configuration once it's fetched
     */
    useEffect(() => {
        if (!isConfigFetched) {
            return;
        }
        const config = new Map();

        if (configData) {
            Object.entries(configData).forEach((value, index) => {
                config.set(value[0], value[1]);
            });
        }

        if (config.has("DETECT_HOST") && config.get("DETECT_HOST") === true) {
            // Only set hostname if not localhost
            const dontIgnoreLocalhost = config.has("IGNORE_LOCALHOST") && config.get("IGNORE_LOCALHOST") === false;
            if (dontIgnoreLocalhost || !["127.0.0.1", "localhost"].includes(window.location.hostname)) {
                const hostname = window.location.hostname;
                config.set("HOSTNAME", hostname);
            }
        }

        console.log("Server: ", config.get("HOSTNAME"));
        // Replace {{HOSTNAME}} in other settings
        if (config.has("HOSTNAME")) {
            const hostname = config.get("HOSTNAME");
            const keysToCheck = ["API_BASE_URL", "MQTT_HOSTNAME"];
            keysToCheck.forEach((value) => {
                if (config.has(value)) {
                    config.set(value, String(config.get(value)).replace("{{HOSTNAME}}", hostname));
                }
            });
        }

        if (config.has("API_BASE_URL") && config.has("MQTT_HOSTNAME")) {
            config.set(
                "API_BASE_URL",
                String(config.get("API_BASE_URL")).replace("{{MQTT_HOSTNAME}}", config.get("MQTT_HOSTNAME"))
            );
        }

        dataStore.set.config(config);
        dataStore.set.isConfigLoaded(true);
    }, [isConfigFetched]);

    /**
     * Flag as initialised
     */
    useEffect(() => {
        if (isDataInitialised) {
            return;
        }
        if (!(isConfigLoaded && isDataFetched && isEnvInitialised && isNodeDataProcessed)) {
            return;
        }
        dataStore.set.isDataInitialised(true);
    }, [isDataFetched, isEnvInitialised, isNodeDataProcessed]);

    if (!isConfigLoaded) {
        return null;
    }

    return (
        <Fragment>
            {!isEnvInitialised && <InitialiseEnvironment />}
            {isEnvInitialised && !isDataFetched && <FetchData />}
            {isDataFetched && !isNodeDataProcessed && <ProcessInitialData />}
        </Fragment>
    );
}
