// import BuildingIcon from "@mui/icons-material/ApartmentRounded";
// import StorageIcon from "@mui/icons-material/BatteryChargingFullRounded";
// import GeneratorIcon from "@mui/icons-material/FactoryRounded";
// import GridIcon from "@mui/icons-material/GridOnRounded";
// import HouseIcon from "@mui/icons-material/HomeRounded";
import LightbulbCircleRoundedIcon from "@mui/icons-material/LightbulbCircleRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import SensorsOffRoundedIcon from "@mui/icons-material/SensorsOffRounded";

// import BuildingIcon from "../../images/grid/building.svg";
// import PowerStationIcon from "../../images/power-station.svg";
// import OfficeIcon from "../../images/office.svg";
// import HouseIcon from "../../images/house.svg";
// import GridIcon from "../../images/grid.svg";
// import FactoryIcon from "../../factory.svg";
// import BatteryIcon from "../../storage.svg";
import BatteryIcon from "../svg/Battery";
import PowerStationIcon from "../svg/PowerStation";
import OfficeIcon from "../svg/Office";
import HouseIcon from "../svg/House";
import GridIcon from "../svg/Grid";
import FactoryIcon from "../svg/Factory";
import BuildingIcon from "../svg/Building";

import {
    Box,
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { has } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonContainer from "../layout/buttons/ButtonContainer";
import ButtonRefresh from "../layout/buttons/ButtonRefresh";
import Card from "../layout/components/Card";
import LoadingBackdrop from "../layout/loading/LoadingBackdrop";
import LoadingIndicator from "../layout/loading/LoadingIndicator";
import SectionTitle from "../layout/SectionTitle";
import NodeEdit from "./NodeEdit";

import dataStore, { actions, useStore } from "../../store/store";
import { MqttQos } from "../../lib/mqtt/PiMqttHelpers";
import getNodeIconNameByType from "../../lib/nodes/node-icons";
import NodeIcon from "./NodeIcon";
import ButtonReset from "../layout/buttons/ButtonReset";

/**
 * @todo: lift state up so it's not reloaded every time
 */

export default function NodeManagerScreen() {
    const enqueueMessage = dataStore.get.enqueueMqttMessage();

    const nodes = useStore().data.nodes();

    const navigate = useNavigate();

    const [displayNodes, setDisplayNodes] = useState([]);
    const [nodeTable, setNodeTable] = useState([]);
    const [activeNode, setActiveNode] = useState(null);
    const [refreshNodes, setRefreshNodes] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activeNodeId, setActiveNodeId] = useState(false);
    const [nodeConfigWatch, setNodeConfigWatch] = useState(false);
    const [nodeResponsed, setNodeResponsed] = useState(false);

    /**
     * User clicks Refresh Nodes button
     * @param {event} e
     */
    const handleClickResetNodes = (e) => {
        // window.location.reload(true);
        e.preventDefault();
        enqueueMessage({
            topic: "all",
            message: "reset",
        });
    };

    /**
     * A node is clicked on, open node editor
     * @param {event} event
     * @returns
     */
    const clickNode = (event) => {
        const nodeId =
            event.target.dataset.id ||
            event.target.parentNode.dataset.id ||
            event.target.parentNode.parentNode.dataset.id ||
            event.target.parentNode.parentNode.parentNode.dataset.id ||
            null;

        if (nodeId === null || nodeId === undefined || !nodes.has(nodeId)) {
            return;
        }

        const targetNode = nodes.get(nodeId);
        setNodeResponsed(false);
        setNodeConfigWatch(false);
        setActiveNodeId(nodeId);
        setActiveNode(targetNode);
    };

    const fetchNodeIdForCommand = (event) => {
        console.log(event);
        const nodeId =
            event.target.dataset.id ||
            event.target.parentNode.dataset.id ||
            event.target.parentNode.parentNode.dataset.id ||
            event.target.parentNode.parentNode.parentNode.dataset.id ||
            event.target.parentNode.parentNode.parentNode.parentNode.dataset.id ||
            null;

        return nodeId;
    };

    const handleCommandReconnect = (event) => {
        const nodeId = fetchNodeIdForCommand(event);
        if (nodeId === null || nodeId === undefined) {
            return;
        }
        enqueueMessage({ topic: nodeId, message: "nickName" });
    };

    const handleCommandFlash = (event) => {
        const nodeId = fetchNodeIdForCommand(event);
        if (nodeId === null || nodeId === undefined) {
            return;
        }
        enqueueMessage({
            topic: nodeId,
            message: "flash",
        });
    };

    const handleCommandRestart = (event) => {
        const nodeId = fetchNodeIdForCommand(event);
        console.log(nodeId);
        if (nodeId === null || nodeId === undefined) {
            return;
        }
        enqueueMessage({
            topic: nodeId,
            message: "restart",
        });
    };

    const handleSave = (data) => {
        if (!activeNode || !activeNodeId) {
            console.log("No active node, skipping");
            return;
        }

        console.log(data);

        const activeId = activeNodeId;
        const targetNode = nodes.get(activeId);

        if (has(data, "node_type")) {
            const nodeType = data.node_type;
            const iconName = getNodeIconNameByType(nodeType);
            if (iconName) {
                data = {
                    ...data,
                    icon: iconName,
                };
            }
        }

        if (has(data, "load_profile_type")) {
            const loadProfileOption = data.load_profile_type;
            if (loadProfileOption !== "custom") {
                enqueueMessage({
                    topic: "preset/set_preset",
                    message: activeId + "," + loadProfileOption,
                    qos: MqttQos.ATLEAST_ONCE,
                });
            }
            delete data.load_profile_type;
        }

        const nodesClone = new Map([...nodes]);
        nodesClone.delete(activeNodeId);
        actions.data.nodes(nodesClone);

        if (has(data, "load_profile")) {
            console.log("Setting load profile", activeNodeId, data.load_profile);
            enqueueMessage({
                topic: activeNodeId + "/set_config/load_profile/string",
                message: data.load_profile,
                qos: MqttQos.ATLEAST_ONCE,
            });
            delete data.load_profile;
        }

        for (const key in data) {
            enqueueMessage({
                topic: activeNodeId + "/set_config/" + key,
                message: data[`${key}`],
                qos: MqttQos.ATLEAST_ONCE,
            });
        }

        if (has(data, "nick_name")) {
            enqueueMessage({
                topic: activeNodeId,
                message: "nickName",
                qos: MqttQos.ATLEAST_ONCE,
            });
        }

        enqueueMessage({
            topic: activeNodeId,
            message: "config",
            qos: MqttQos.ATLEAST_ONCE,
        });

        setActiveNodeId(null);
        setActiveNode(false);
    };

    const handleCancel = () => {
        setActiveNode(null);
    };

    useEffect(() => {
        const nodeArray = [];
        const mustHaves = ["id", "nick_name", "node_type"];
        nodes.forEach((node) => {
            const checkMustHaves = mustHaves.filter((checkProp) => {
                return !has(node, checkProp);
            });
            if (checkMustHaves && checkMustHaves.length) {
                return;
            }

            nodeArray.push(node);
        });

        if (!nodeArray || nodeArray.length < 1) {
            setDisplayNodes([]);
            return;
        }

        setDisplayNodes(nodeArray);
    }, [nodes]);

    const nodeCommands = (
        <Fragment>
            <IconButton aria-label="reconnect" color="warning" title="Reconnect" onClick={handleCommandReconnect}>
                <SensorsOffRoundedIcon />
            </IconButton>
            <IconButton aria-label="flash" color="info" title="Flash LED" onClick={handleCommandFlash}>
                <LightbulbCircleRoundedIcon />
            </IconButton>
            <IconButton aria-label="restart" color="error" title="Restart Node" onClick={handleCommandRestart}>
                <RestartAltRoundedIcon />
            </IconButton>
        </Fragment>
    );

    useEffect(() => {
        if (!displayNodes || !displayNodes.length) {
            setNodeTable(null);
            return;
        }
        const tempNodeTable = displayNodes.map((item) => {
            const iconName = String(item.icon);
            // const icon = icons.has(iconName) ? icons.get(iconName) : null;
            return (
                <TableRow
                    key={item.id}
                    data-id={item.id}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell
                        component={"th"}
                        scope="row"
                        onClick={clickNode}
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        <Box sx={{ width: "1.8rem", height: "1.8rem" }}>
                            <NodeIcon iconName={iconName} />
                        </Box>
                    </TableCell>
                    <TableCell
                        onClick={clickNode}
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        {item.nick_name && item.nick_name}
                        {!item.nick_name && <em>None</em>}
                    </TableCell>
                    <TableCell
                        onClick={clickNode}
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        {item.id}
                    </TableCell>
                    <TableCell>
                        {nodeCommands}
                        <span style={{ color: "#666", fontSize: "10px" }}>
                            {item.node_version ? item.node_version : ""}
                        </span>
                    </TableCell>
                </TableRow>
            );
        });
        setNodeTable(tempNodeTable);
    }, [displayNodes]);

    if (activeNode) {
        return <NodeEdit node={activeNode} saveHandler={handleSave} cancelHandler={handleCancel} />;
    }

    if (!displayNodes || displayNodes.length < 1) {
        return (
            <Fragment>
                <Card>
                    <SectionTitle>No nodes currently connected.</SectionTitle>
                </Card>
                {/* <ButtonContainer>
                    <ButtonRefresh onClick={handleClickRefreshNodes} />
                </ButtonContainer> */}
            </Fragment>
        );
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 640 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <SectionTitle>Node Name</SectionTitle>
                            </TableCell>
                            <TableCell>
                                <SectionTitle>ID</SectionTitle>
                            </TableCell>
                            <TableCell>
                                <SectionTitle>Control</SectionTitle>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{nodeTable}</TableBody>
                </Table>
            </TableContainer>
            <ButtonContainer>
                <ButtonReset onClick={handleClickResetNodes} />
            </ButtonContainer>

            {isLoading && <CircularProgress disableShrink />}
            <LoadingBackdrop text="One moment..." open={isLoading} />
        </Box>
    );
}
