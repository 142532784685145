import { useEffect, useState } from "react";
import useSWR from "swr";
import dataStore from "../../store/store";

const fetcher = (...args) =>
    fetch(...args)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw response;
        })
        .catch((error) => {
            throw new Error(error);
        });

/**
 * @param {string} endpoint
 */
export function useApiGet(endpoint, options = undefined) {
    const API_BASE = dataStore.get.apiBaseUrl();
    if (!endpoint) {
        throw new Error("No endpoint specified");
    }
    const url = API_BASE + endpoint;
    const { data, error } = useSWR(url, fetcher, options);

    return {
        data,
        isLoading: !error && !data,
        isError: Boolean(error),
        error,
    };
}

export function useApiPost(endpoint, body) {
    const API_BASE = dataStore.get.apiBaseUrl();
    if (!endpoint) {
        throw new Error("No endpoint specified");
    }
    const url = API_BASE + endpoint;

    const [result, setResult] = useState(null);
    const [isFinished, setIsFinished] = useState(false);
    const [error, setError] = useState(null);

    useEffect(async () => {
        const fetchResult = await fetch(url, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(body),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw response;
            })
            .catch((error) => {
                throw error;
            });
        setResult(fetchResult);
        setIsFinished(true);
    }, []);

    return {
        result,
        isFinished,
        isError: Boolean(error),
        error,
    };
}
