import { Fragment, useEffect } from "react";
import dataStore, { actions } from "../../store/store";

export default function MqttMessageHandlers(props) {
    const addMessageHandler = dataStore.get.addMqttMessageHandler();
    /**
     * Handle gui/event/# message
     *
     * @param {string} topic
     * @param {string} message
     */
    const handleGuiEventMessage = (topic, message) => {
        if (topic === "gui/event/sim_active") {
            handleSimActiveMessage(topic, message);
            return;
        }

        if (topic === "gui/event/set_time_mode") {
            dataStore.set.simEnvironment({ ...dataStore.get.simEnvironment(), sim_mode: message });
            if (message !== dataStore.get.simEnvironmentMode()) {
                dataStore.set.simEnvironmentMode(message);
            }
            return;
        }

        if (topic === "gui/event/blackouts") {
            const blackouts = message.trim().toLowerCase() === "on";
            if (blackouts !== dataStore.get.blackouts()) {
                dataStore.set.blackouts(blackouts);
            }
            return;
        }

        if (topic === "gui/event/dynamic_time_step") {
            const isDynamic = message.trim().toLowerCase() === "true";
            if (isDynamic !== dataStore.get.isDynamicTimeStep()) {
                dataStore.set.isDynamicTimeStep(isDynamic);
            }
            return;
        }

        return;
        const topicSplit = topic.split("/");
        const topicChild = topicSplit.pop();
        const match = topicChild.match(/set_(.+)/);
        if (!match) {
            return;
        }
        const target = match[1] === "windspeed" ? "wind_speed" : match[1];
        const newValue = isNaN(message) ? message : parseFloat(message);
        const newItem = { [`${target}`]: newValue };
        console.log(topic, message);
    };

    const handleGuiInfoMessage = (topic, message) => {
        if (topic === "gui/info/real_time_factor") {
            const rtf = parseFloat(message).toFixed(3);
            if (!isNaN(rtf)) {
                dataStore.set.realTimeFactor(rtf);
            }
            return;
        }

        message = message.toLowerCase();
        if (message === "blackout") {
            // dataStore.set.requestSimToggle(true);
            console.log("BLACKOUT!!!");
            dataStore.set.blackouts(true);
            const blackoutsActive = dataStore.get.blackoutsActive();
            blackoutsActive.set(topic, true);
            dataStore.set.blackoutsActive(blackoutsActive);
            dataStore.set.isBlackout(true);
            return;
        }
        if (message === "reset") {
            console.log("Blackout reset");
            const blackoutsActive = dataStore.get.blackoutsActive();
            blackoutsActive.set(topic, false);
            dataStore.set.blackoutsActive(blackoutsActive);
            dataStore.set.isBlackout(false);
            return;
        }
    };

    const handleAllMessage = (topic, message) => {
        message = message.toLowerCase();
        if (message === "blackout" || message === "reset") {
            handleGuiInfoMessage(topic, message);
        }
    };

    const handleSimActiveMessage = (topic, message) => {
        const simActive = String(message).trim().toLowerCase() === "true";
        if (simActive !== dataStore.get.isSimRunning()) {
            dataStore.set.isSimRunning(simActive);
        }
    };
    const handleGuiPresetsDetailsMessage = (topic, message) => {
        actions.data.isPresetsFetched(true);
        actions.data.presets(message.split(","));
    };

    useEffect(() => {
        addMessageHandler({
            topic: "gui/event/#",
            handlerFunction: handleGuiEventMessage,
        });
        addMessageHandler({
            topic: "gui/info/#",
            handlerFunction: handleGuiInfoMessage,
        });
        addMessageHandler({
            topic: "all",
            handlerFunction: handleAllMessage,
        });
        addMessageHandler({
            topic: "gui/presets/details",
            handlerFunction: handleGuiPresetsDetailsMessage,
        });
        actions.data.isMqttMessageHandlersAdded(true);
    }, []);

    return <Fragment>{props.children}</Fragment>;
}
