import { green, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const darkThemeOptions = {
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#000",
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#000",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    border: "1px solid #666",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    marginBottom: 6,
                    padding: "8px 12px 8px 12px",
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid #666",
                },
                head: {
                    fontWeight: "bold",
                },
            },
        },
    },
    palette: {
        type: "dark",
        primary: {
            main: "#e12744",
        },
        secondary: {
            main: "#1d1a1a",
        },
        background: {
            default: "#000000",
            paper: "#1d1a1a",
        },
        success: {
            main: green[700],
        },
        error: {
            main: red[700],
        },
        text: {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.5)",
            disabled: "rgba(255, 255, 255, 0.25)",
        },
        action: {
            disabled: "rgba(255, 255, 255, 0.25)",
            disabledOpacity: 0.25,
            disabledBackground: "rgba(255, 255, 255, 0.25)",
        },
        divider: "rgba(255, 255, 255, 0.2)",
    },
    typography: {
        fontFamily: "Montserrat, sans-serif",
    },
};
const theme = createTheme(darkThemeOptions);

export default theme;
