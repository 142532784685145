import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import DemoScreen from "./components/demo/DemoScreen";
import EnvironmentScreen from "./components/environment/EnvironmentScreen";
import ErrorScreen from "./components/error/ErrorScreen";
import GridViewScreen from "./components/grid/GridViewScreen";
import NodeEditorScreen from "./components/nodes/NodeEditorScreen";
import NodeManagerScreen from "./components/nodes/NodeManagerScreen";
import PowerTrendsScreen from "./components/power/PowerTrendScreen";
import SimulationScreen from "./components/simulation/SimulationScreen";
import "./index.scss";

const appElement = "root";
ReactModal.setAppElement("#" + appElement);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="grid" element={<GridViewScreen />} />
                    <Route path="power" element={<PowerTrendsScreen />} />
                    <Route path="environment" element={<EnvironmentScreen />} />
                    <Route path="nodes" element={<NodeManagerScreen />} />
                    <Route path="node">
                        <Route path=":nodeId" element={<NodeEditorScreen />} />
                    </Route>
                    <Route path="simulation" element={<SimulationScreen />} />
                    <Route path="error" element={<ErrorScreen />} />
                    <Route path="demo" element={<DemoScreen />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById(appElement)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
