import { useSubscription } from "mqtt-react-hooks";
import { Fragment, useEffect, useState } from "react";
import { MqttQos } from "../../lib/mqtt/PiMqttHelpers";
import dataStore from "../../store/store";

export default function MqttSubscriptionHandler(props) {
    const isSubscribed = dataStore.use.isMqttSubscribed();
    const isConnected = dataStore.use.isMqttConnected();
    const [subscribedTopics, setSubscribedTopics] = useState([]);
    const subscriptionHandler = useSubscription(subscribedTopics, { qos: MqttQos.ATLEAST_ONCE });

    useEffect(() => {
        setSubscribedTopics(["#"]);
    }, []);

    useEffect(() => {
        if (!isSubscribed && subscriptionHandler.connectionStatus === "Connected") {
            dataStore.set.isMqttSubscribed(true);
        }
        dataStore.set.mqttSubscriptionHandler(subscriptionHandler);
    }, [subscriptionHandler]);

    return <Fragment>{props.children}</Fragment>;
}
