import "@fontsource/montserrat";
import React from "react";
import DataProvider from "./components/data/DataProvider";
import StateHandler from "./components/data/StateHandler";
import Layout from "./components/layout/Layout";
import MqttProvider from "./components/mqtt/MqttProvider";
import "./styles/App.scss";

export default function App() {
    return (
        <StateHandler>
            <DataProvider>
                <MqttProvider />
                <Layout />
            </DataProvider>
        </StateHandler>
    );
}
