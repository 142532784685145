import { Fragment, useEffect } from "react";
import useWindowSize from "../../lib/data/useWindowSize";
import dataStore from "../../store/store";

export default function InitialiseEnvironment(props) {
    const { windowWidth, windowHeight } = useWindowSize();

    const config = dataStore.get.config();

    // Read in env settings
    useEffect(() => {
        const API_BASE = String(config.get("API_BASE_URL")).replace(/\/$/, "");
        const debug = Boolean(config.get("DEBUG_MODE"));
        dataStore.set.debug(debug);

        dataStore.set.apiBaseUrl(API_BASE);
        const vars = ["PORT", "HOSTNAME", "KEEPALIVE", "USERNAME", "PASSWORD", "CLIENTID"];
        let mqttSettings = dataStore.get.mqttOptions();
        vars.forEach((value, index) => {
            const key = "MQTT_" + value;
            let envValue = config.has(key) ? config.get(key) : undefined;
            if (envValue) {
                if (!isNaN(envValue)) {
                    envValue = parseInt(envValue);
                }
                mqttSettings[`${value.toLowerCase()}`] = envValue;
            }
        });

        if (windowHeight > 500 && windowWidth >= 760) {
            dataStore.set.isSmallWindow(false);
        } else {
            dataStore.set.isSmallWindow(true);
        }

        dataStore.set.mqttOptions(mqttSettings);
        dataStore.set.isEnvInitialised(true);
    }, []);

    return <Fragment>{props.children}</Fragment>;
}
