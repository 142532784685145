import { has } from "lodash";
import React, { useEffect, useState } from "react";

import Graph from "react-graph-vis";
import { getNodeIconImage } from "../../lib/nodes/node-icons";
import dataStore from "../../store/store";
import useData from "../../store/useData";

const graphOptions = {
    height: "700px",
    layout: {
        randomSeed: 23,
        hierarchical: {
            enabled: true,
            direction: "LR",
            sortMethod: "hubsize",
            nodeSpacing: 100,
            treeSpacing: 100,
            levelSeparation: 200,
        },
    },
    interaction: {
        dragNodes: false,
        selectable: false,
        selectConnectedEdges: false,
    },

    nodes: {
        physics: false,
        font: {
            size: 16,
            color: "#fff",
            face: "Montserrat, sans-serif",
        },
        shape: "image",
        size: 20,
        borderWidth: 1,
        imagePadding: 10,
        color: {
            border: "#e12744",
            background: "#000",
        },
        shapeProperties: {
            useBorderWithImage: true,
        },
    },

    edges: {
        color: "#fff",
        arrowStrikethrough: false,
        arrows: {
            to: {
                enabled: false,
            },
            from: {
                enabled: false,
            },
        },
        dashes: true,
        smooth: {
            type: "continuous",
        },
    },
};

export default function GridNodes(props) {
    const autoLink = props.autoLink ?? false;
    
    const addNode = (nodeId) => {
        if (nodeIds.indexOf(nodeId) >= 0) {
            return;
        }

        const node = dataStore.get.nodes().get(nodeId);
        nodeIds.push(nodeId);
        const nodeIcon = getNodeIconImage(node.icon);
        nodes.push({
            id: nodeId,
            group: node.type,
            label: node.nick_name ?? nodeId,

            image: {
                selected: nodeIcon,
                unselected: nodeIcon,
            },
        });
    };

    const events = {
        dragStart: (e) => {
            e.event.preventDefault();
            return false;
        },
        select: ({ nodes, edges }) => {
            console.log("Selected nodes:");
            console.log(nodes);
            console.log("Selected edges:");
            console.log(edges);
        },
    };

    let nodes = [];
    let nodeIds = [];
    let edges = [];
    let gridNodes = [];
    let powerStationNodes = [];
    useData()
        .topology()
        .forEach((value) => {
            if (!dataStore.get.nodes().has(value.id1) || !dataStore.get.nodes().has(value.id2)) {
                return;
            }
            addNode(value.id1);
            addNode(value.id2);
            edges.push({ from: value.id1, to: value.id2 });
        });

    // Add orphaned nodes
    useData()
        .nodes()
        .forEach((value) => {
            if (value.node_type === "generator") {
                powerStationNodes.push(value);
            }
            if (value.node_type === "grid") {
                gridNodes.push(value);
            }
            addNode(value.id);
        });


    
    const [state, setState] = useState({
        counter: 6,
        graph: {
            nodes: nodes,
            edges: edges,
            // nodes: [
            //     { id: 1, label: "House 1", color: "#ffffff" }, // , shape: "image", image: HouseIcon \},
            //     { id: 2, label: "House 2", color: "#ffffff" }, // , shape: "image", image: HouseIcon \},
            //     { id: 3, label: "House 3", color: "#ffffff" }, // , shape: "image", image: HouseIcon \},
            //     { id: 4, label: "House 4", color: "#ffffff" }, // , shape: "image", image: HouseIcon \},
            //     { id: 5, label: "Transformer", color: "#ffffff" }, //, shape: "image", image: GridIcon },
            //     { id: 6, label: "Power Station", color: "#ffffff" }, // shape: "image", image: PowerStationIcon },
            // ],
            // edges: [
            //     { from: 1, to: 2 },
            //     { from: 2, to: 5 },
            //     { from: 3, to: 5 },
            //     { from: 4, to: 5 },
            //     { from: 5, to: 6 },
            // ],
        },
    });

    useEffect(() => {
        if (!autoLink) {
            return;
        }
        gridNodes.forEach((value) => {
            // console.log(value);
            // edges.push({ from: value.id1, to: value.id2 });
        });
    }, [state]);

    const { graph } = state;

    let graphOptionsFinal = graphOptions;
    if (has(props, "graphOptions")) {
        graphOptionsFinal = { ...graphOptionsFinal, ...props.graphOptions };
    }

    return (
        <div>
            <Graph graph={graph} options={graphOptionsFinal} events={events} />
        </div>
    );
}
