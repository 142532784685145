import { MenuItem, Select } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useApiPost } from "../../lib/api/useApiRequest";
import { MqttTopic } from "../../lib/mqtt/PiMqttHelpers";
import dataStore from "../../store/store";
import useData from "../../store/useData";
import Card from "../layout/components/Card";
import FormControl from "../layout/components/FormControl";
import GridContainer from "../layout/grid/GridContainer";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";
import LoadingIndicator from "../layout/loading/LoadingIndicator";
import EnvironmentConfigUpdate from "./EnvironmentConfigUpdate";
import EnvironmentSandbox from "./EnvironmentSandbox";
import EnvironmentScenario from "./EnvironmentScenario";

const environmentModes = [
    {
        name: "scenario",
        label: "Scenario",
    },
    {
        name: "sensors",
        label: "Sensors",
    },
    {
        name: "sandbox",
        label: "Sandbox",
    },
];

export default function EnvironmentScreen() {
    const enqueueMessage = dataStore.get.enqueueMqttMessage();

    // const [environmentMode, setEnvironmentMode] = useState("scenario");
    const environmentMode = useData().simEnvironmentMode();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [doConfigUpdate, setDoConfigUpdate] = useState(false);
    const [configUpdateData, setConfigUpdateData] = useState(null);
    const [isConfigUpdated, setIsConfigUpdated] = useState(false);

    /**
     * Handle environment mode change
     * @param {*} param0
     */
    const handleEnvironmentModeChange = ({ target }) => {
        const findMode = environmentModes.filter((value, index) => {
            return value.name === target.value;
        });
        if (!findMode || findMode.length < 1) {
            return;
        }
        const newMode = findMode[0].name ?? "";
        if (!newMode) {
            return;
        }
        enqueueMessage({
            message: newMode,
            topic: "gui/event/set_time_mode",
        });
        dataStore.set.simEnvironmentMode("");
        setIsDataLoaded(false);
    };

    const handleEnvironmentDataChange = ({ irradiance, temperature, wind_speed }) => {
        if (irradiance !== undefined) {
            enqueueMessage({
                topic: "gui/event/set_irradiance",
                message: irradiance,
            });
        }
        if (temperature !== undefined) {
            enqueueMessage({
                topic: "gui/event/set_temperature",
                message: temperature,
            });
        }
        if (wind_speed !== undefined) {
            enqueueMessage({
                topic: "gui/event/set_windspeed",
                message: wind_speed,
            });
        }
    };

    useEffect(() => {
        if (!isDataLoaded && environmentMode !== "" && !doConfigUpdate) {
            setIsDataLoaded(true);
        }
    }, [environmentMode, doConfigUpdate]);

    /**
     * Update environment configuration change
     * @param {object} updateConfig
     */
    const handleEnvironmentConfigChange = (updateConfig) => {
        setIsDataLoaded(false);
        setConfigUpdateData(updateConfig);
        setDoConfigUpdate(true);
    };

    /**
     * Update environment configuration change
     * @param {object} updateConfig
     */
    const handleEnvironmentConfigUpdate = (updateConfig) => {
        dataStore.set.environmentConfig(updateConfig.result.config);
        setDoConfigUpdate(false);
    };

    if (!isDataLoaded) {
        return (
            <Fragment>
                {doConfigUpdate && (
                    <EnvironmentConfigUpdate
                        configUpdateData={configUpdateData}
                        updatedHandler={handleEnvironmentConfigUpdate}
                    />
                )}
                <LoadingIndicator />
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Card>
                <GridContainer>
                    <GridItem1>Mode</GridItem1>
                    <GridItem2>
                        <FormControl>
                            <Select
                                name="environment_mode"
                                value={environmentMode}
                                onChange={handleEnvironmentModeChange}
                            >
                                {environmentModes.map((value, index) => {
                                    return (
                                        <MenuItem key={index} value={value.name}>
                                            {value.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </GridItem2>
                </GridContainer>
            </Card>
            {environmentMode !== "sensors" && (
                <Card>
                    {environmentMode === "scenario" && (
                        <EnvironmentScenario environmentConfigChangeHandler={handleEnvironmentConfigChange} />
                    )}
                    {environmentMode === "sandbox" && (
                        <EnvironmentSandbox environmentDataChangeHandler={handleEnvironmentDataChange} />
                    )}
                </Card>
            )}
        </Fragment>
    );
}
