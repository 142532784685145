import { Fragment, useEffect } from "react";
import dataStore from "../../store/store";

export default function StateHandler(props) {
    const isConnected = dataStore.use.isMqttConnected();
    const isDataInitialised = dataStore.use.isDataInitialised();
    const isMqttInitialised = dataStore.use.isMqttInitialised();

    useEffect(() => {
        const areWeGo = isConnected && isDataInitialised && isMqttInitialised;
        if (dataStore.get.isInitialised() === areWeGo) {
            return;
        }
        dataStore.set.isInitialised(areWeGo);
    }, [isConnected, isDataInitialised, isMqttInitialised]);

    return <Fragment>{props.children}</Fragment>;
}
