import { Fragment, useEffect } from "react";
import dataStore, { actions, store, useStore } from "../../store/store";

export default function NodeData(props) {
    const addMessageHandler = dataStore.get.addMqttMessageHandler();
    const nodes = useStore().data.nodes();

    const enqueueMessage = dataStore.get.enqueueMqttMessage();

    const updateNode = (nodeId, newConfigValue) => {
        let requestNickName = false;
        const nodesClone = new Map([...store.data.nodes()]);
        if (!nodesClone.has(nodeId)) {
            nodesClone.set(nodeId, { id: nodeId });
            console.log("adding node", nodeId);
            addMessageHandler({
                topic: nodeId,
                handlerFunction: handleNodeMessage,
            });
            setTimeout(() => {
                enqueueMessage({ topic: nodeId, message: "nickName" });
            }, 1000);
        }

        nodesClone.set(nodeId, { ...nodesClone.get(nodeId), ...newConfigValue });
        actions.data.nodes(nodesClone);
    };

    useEffect(() => {
        nodes.forEach((node, index) => {
            addMessageHandler({
                topic: node.id,
                handlerFunction: handleNodeMessage,
            });
        });
    }, []);

    /**
     * Handle pi/simenvironment MQTT message
     *
     * @param {string} topic
     * @param {string} message
     */
    const handlePiConfigMessage = (topic, message) => {
        const topicSplit = topic.split("/");
        // const type = topicSplit.pop();
        // const key = topicSplit.pop();
        // const nodeId = topicSplit.pop();

        const [, , nodeId, key, type] = topicSplit;

        let value = message;
        if (type.trim().toLowerCase() === "float") {
            value = parseFloat(value);
        }

        const newConfigValue = { [`${key}`]: value };

        updateNode(nodeId, newConfigValue);
    };

    /**
     * Handle pi/simdata MQTT message
     *
     * @param {string} topic
     * @param {string} message
     */
    const handlePiSimDataMessage = (topic, message) => {
        const topicSplit = topic.split("/");
        const [, , nodeId, key] = topicSplit;
        const newConfigValue = { [`${key}`]: parseFloat(message) };

        updateNode(nodeId, newConfigValue);
    };

    /**
     * Handle pi/connect MQTT message
     *
     * @param {string} topic
     * @param {string} message
     */
    const handlePiConnectMessage = (topic, message) => {
        const messageSplit = message.split(",");
        const [nodeId, nickName] = messageSplit;

        const newConfigValue = { id: nodeId, nick_name: nickName };

        updateNode(nodeId, newConfigValue);
    };

    const handleNodeMessage = (topic, message) => {
        if (!nodes.has(topic)) {
            return;
        }
        if (message.toLowerCase() === "blackout") {
            console.log("BLACKOUT!!!2");
            dataStore.set.blackouts(true);
            const blackoutsActive = dataStore.get.blackoutsActive();
            blackoutsActive.set(topic, true);
            dataStore.set.blackoutsActive(blackoutsActive);

            // Stop and start sim
            // Not sure why exactly but occurs in existing GUI.py
        }
    };

    useEffect(() => {
        addMessageHandler({
            topic: "pi/config/#",
            handlerFunction: handlePiConfigMessage,
        });

        addMessageHandler({
            topic: "pi/simdata/#",
            handlerFunction: handlePiSimDataMessage,
        });

        addMessageHandler({
            topic: "pi/connect",
            handlerFunction: handlePiConnectMessage,
        });
    }, []);

    return <Fragment></Fragment>;
}
