import { Box, Drawer, List } from "@mui/material";
import { layoutOptions } from "../Layout";
import MenuLogo from "./MenuLogo";

export default function DrawerContainer(props) {
    const { drawerOpen, hideLogo, smallWindow } = props;
    const drawerWidth = drawerOpen ? layoutOptions.drawerWidthOpen : layoutOptions.drawerWidthClosed;
    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                width: drawerWidth,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    borderRight: (theme) => "1px solid " + theme.palette.primary.dark,
                    transition: layoutOptions.drawerTransition,
                    overflow: "hidden",
                },
                background: "#000",
            }}
        >
            <Box>
                {props.children}
                {!hideLogo && <MenuLogo {...props} />}
            </Box>
        </Drawer>
    );
}
