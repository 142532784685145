import { MenuItem, Select } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";

import dataStore, { actions, useStore } from "../../store/store";
import useData from "../../store/useData";
import GridNodes from "../grid/GridNodes";
import FormControl from "../layout/components/FormControl";
import GridContainer from "../layout/grid/GridContainer";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";
import CombinedTrendScreen from "../power/CombinedTrend";
import DemoItem from "./DemoItem";
import { has } from "lodash";
import useFetchNodesConnectionsData from "../../lib/data/useFetchNodesConnectionsData";
import Button from "../layout/buttons/Button";

/**
 * Convert numeric seconds to HH:MM:SS string
 *  @todo: refactor this function, gets used a few places!
 *
 * @param {number} seconds
 * @returns HH:MM:SS
 */
const clockString = (seconds) => {
    // In Python: divmod(x,y) -> (quotient, remainder)
    seconds = seconds % (24 * 3600);
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = Math.round(seconds % 60);
    return String(
        String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0")
    );
};

export default function DemoScreen(props) {
    const [isNodeConnectionDataLoaded, nodeConnectionsData] = useFetchNodesConnectionsData({
        options: { refreshInterval: 5000 },
    });
    const [activeNodes, setActiveNodes] = useState(0);
    // const [realTimeFactor, setRealTimeFactor] = useState(0);
    const [updateData, setUpdateData] = useState(false);
    const [updateTimer, setUpdateTimer] = useState(null);

    const [simEnvironmentData, setSimEnvironmentData] = useState({
        frequency: "-",
        accumulation: "-",
        netLoad: "-",
        netGen: "-",
        netInertia: "-",
        time: "-",
        irradiance: "-",
        temperature: "-",
        windSpeed: "-",
    });

    const isBlackout = dataStore.use.isBlackout();

    const data = useData();

    const environmentMode = useData().simEnvironmentMode();

    const enqueueMessage = dataStore.get.enqueueMqttMessage();

    const realTimeFactor = dataStore.get.realTimeFactor();

    const resetBlackout = () => {
        enqueueMessage({
            topic: "all",
            message: "reset",
        });
    };

    /**
     * On load:
     * 1. Start simulation if not already.
     *
     * Display:
     * - Grid
     * - Power Trends
     * - Current mode: Scenario / Sensors / Sandbox
     *  - Scenario:
     *      - Irradiance, Temp and Wind Speed profiles
     * - Current:
     *  - Irradiance, Temp, Wind Speed
     *  - Frequency
     *  - Accumulation
     *  - Net Load
     *  - Net Gen
     *  - Net Inertia
     *  - Sim Time
     *  - Real Time Factor
     */

    // useEffect(() => {
    //     // Check simulation is active
    //     handlerSimulationStart();
    // }, [isSimRunning]);

    const handleCommandRestart = (event) => {
        enqueueMessage({
            topic: "#",
            message: "restart",
        });
    };

    useEffect(() => {
        // actions.data.requestSimStart(true);

        setUpdateData(true);
        return () => {
            setUpdateData(false);
        };
    }, []);

    useEffect(() => {
        if (!updateData) {
            return;
        }

        const simData = dataStore.get.simEnvironmentHistory();
        if (simData.length) {
            const currentSimEnvData = simData[simData.length - 1];
            console.log(currentSimEnvData);
            const required = [
                "time",
                "irradiance",
                "temperature",
                "wind_speed",
                "accumulation",
                "net_gen",
                "net_inertia",
                "frequency",
            ];
            const filterRequired = required.filter((value, index) => {
                return !has(currentSimEnvData, value);
            });
            if (!filterRequired || !filterRequired.length) {
                setSimEnvironmentData({
                    accumulation: parseFloat(currentSimEnvData.accumulation).toFixed(2),
                    netLoad: parseFloat(currentSimEnvData.net_load).toFixed(2),
                    netGen: parseFloat(currentSimEnvData.net_gen).toFixed(2),
                    netInertia: Math.round(currentSimEnvData.net_inertia),
                    time: clockString(currentSimEnvData.time),
                    irradiance: parseFloat(currentSimEnvData.irradiance).toFixed(1),
                    temperature: parseFloat(currentSimEnvData.temperature).toFixed(1),
                    windSpeed: parseFloat(currentSimEnvData.wind_speed).toFixed(1),
                    frequency: parseFloat(currentSimEnvData.frequency).toFixed(1),
                });
            }
        }

        clearTimeout(updateTimer);
        setUpdateTimer(
            setTimeout(() => {
                setUpdateData(true);
            }, 1500)
        );
        setUpdateData(false);
        return () => {
            setActiveNodes(0);
            setUpdateData(false);
            setUpdateTimer(null);
        };
    }, [updateData]);

    useEffect(() => {
        if (!isNodeConnectionDataLoaded || !has(nodeConnectionsData, "data.count")) {
            return;
        }
        setActiveNodes(nodeConnectionsData.data.count);
        return () => {
            setActiveNodes(0);
        };
    }, [nodeConnectionsData]);

    return (
        <Fragment>
            <div>
                <CombinedTrendScreen isBlackout={isBlackout} />
                {isBlackout && (
                    <div style={{ position: "absolute", top: "20px", right: "20px" }}>
                        <Button onClick={resetBlackout}>Reset Blackout</Button>
                    </div>
                )}
            </div>
            <div style={{ marginTop: "24px" }}>
                <GridContainer>
                    <DemoItem label="Active Nodes" value={activeNodes} />
                    <DemoItem label="Sim Time" value={simEnvironmentData.time} />
                    <DemoItem label="Real Time Factor" value={realTimeFactor} />

                    <DemoItem label="Irradiance (W/m&#178;)" value={simEnvironmentData.irradiance} />
                    <DemoItem label="Temperature (&deg;C)" value={simEnvironmentData.temperature} />
                    <DemoItem label="Wind Speed (km/hr)" value={simEnvironmentData.windSpeed} />

                    <DemoItem label="Net Load (kW)" value={simEnvironmentData.netLoad} />
                    <DemoItem label="Net Generation (kW)" value={simEnvironmentData.netGen} />
                    <DemoItem label="Frequency (Hz)" value={simEnvironmentData.frequency} />
                </GridContainer>
            </div>
        </Fragment>
    );
}
