import { PowerOffRounded as BlackoutIcon } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText, Slide, Typography } from "@mui/material";
import dataStore from "../../../store/store";
import MenuItemTransition from "./MenuItemTransition";

export default function BlackoutStatus() {
    const isBlackout = dataStore.use.isBlackout();
    const isInitialised = dataStore.use.isInitialised();

    const icon = <BlackoutIcon htmlColor="#e6f514" className="pulser" />;

    return (
        <Slide in={isBlackout} appear={false} direction="right">
            <ListItem
                sx={{
                    height: isBlackout ? "40px" : 0,
                    overflow: "hidden",
                    flexWrap: "nowrap",
                    flexShrink: "0",
                }}
            >
                <ListItemIcon sx={{ ml: "-1px", mr: "1px" }}>{icon}</ListItemIcon>
                <MenuItemTransition in={isInitialised}>
                    <ListItemText
                        sx={{
                            overflow: "hidden",
                            flexWrap: "nowrap",
                            flexShrink: "0",
                        }}
                    >
                        <Typography>Blackout!</Typography>
                    </ListItemText>
                </MenuItemTransition>
            </ListItem>
        </Slide>
    );
}
