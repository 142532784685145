import { Box } from "@mui/system";
import ZoomImage from "../ZoomImage";
import muLogo from "../../../images/mu-red.png";
import { layoutOptions } from "../Layout";
import { Block } from "@mui/icons-material";
import dataStore from "../../../store/store";

export default function MenuLogo(props) {
    if (dataStore.use.isSmallWindow()) {
        return null;
    }
    return (
        <Box
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 2,
                transition: layoutOptions.drawerTransition,
            }}
        >
            <Box
                sx={{
                    transform: props.drawerOpen ? "" : "rotate(-90deg)",
                    transition: layoutOptions.drawerTransition,
                    mt: "24px",
                    "& img": {
                        width: (props.drawerOpen ? 128 : 32) + "px",
                        transition: layoutOptions.drawerTransition,
                    },
                    position: "absolute",
                    bottom: "10px",
                    left: props.drawerOpen ? "44px" : "18px",
                }}
            >
                <img src={muLogo} alt="Murdoch University" />
            </Box>
        </Box>
    );
}
