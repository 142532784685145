import { Box } from "@mui/material";

export default function ButtonContainer({ children }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                width: "100%",
                marginTop: "4px",
                px: "4px",
                mx: "4px",
            }}
        >
            {children}
        </Box>
    );
}
