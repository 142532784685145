import { Fragment, useEffect } from "react";
import { useApiPost } from "../../lib/api/useApiRequest";

export default function EnvironmentConfigUpdate(props) {
    const { configUpdateData, updatedHandler } = props;

    const result = useApiPost("/environment/config", configUpdateData);
    useEffect(() => {
        if (result.isFinished || result.isError) {
            updatedHandler(result);
        }
    }, [result]);

    return <Fragment></Fragment>;
}
