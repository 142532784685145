import { Fragment, useEffect } from "react";
import dataStore from "../../store/store";
import { has } from "lodash";

export default function ProcessInitialData(props) {
    // Process node connections and configs
    const nodeConnections = dataStore.get.nodeConnections();
    const nodeConnectionsConfig = dataStore.get.nodeConnectionsConfig();
    const simEnvironmentHistory = dataStore.get.simEnvironmentHistory();
    const simEnvironment = dataStore.get.simEnvironment();
    const topologyData = dataStore.get.topology();
    const environmentConfig = dataStore.get.environmentConfig();

    useEffect(() => {
        const connections = nodeConnections.data.connections;
        const config = nodeConnectionsConfig.data.connections;
        const simDataHistory = simEnvironmentHistory.data.history.reverse();
        const topology = topologyData.data.topology;
        const environment = simEnvironment.data.environment;
        const envConfig = environmentConfig.data.config;

        dataStore.set.topology(topology);
        dataStore.set.simEnvironmentHistory(simDataHistory);
        dataStore.set.simEnvironment(environment);
        dataStore.set.environmentConfig(envConfig);

        if (has(environment, "sim_mode")) {
            dataStore.set.simEnvironmentMode(environment.sim_mode);
        }

        const nodes = new Map();

        connections.forEach((element) => {
            if (!has(element, "id") || !has(element, "nick_name")) {
                return;
            }
            nodes.set(element.id, element);
        });

        config.forEach((element) => {
            if (!has(element, "id")) {
                return;
            }

            if (!nodes.has(element.id)) {
                nodes.set(element.id, {});
            }
            nodes.set(element.id, { ...nodes.get(element.id), ...element });
        });

        dataStore.set.nodes(new Map([...dataStore.get.nodes(), ...nodes]));
        dataStore.set.isInitialDataProcessed(true);
    }, []);
    return <Fragment>{props.children}</Fragment>;
}
