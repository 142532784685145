import { grey } from "@mui/material/colors";
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import menuItems from "../../../lib/layout/menu-items";
import MenuItemTransition from "./MenuItemTransition";
import { layoutOptions } from "../Layout";

export default function MenuItems(props) {
    const navigation = useNavigate();
    const location = useLocation();
    const currentLocation = location.pathname.substring(1);

    const disabled = props.disabled ?? false;
    const drawerOpen = props.drawerOpen ?? true;

    const clickMenuLink = (link) => {
        if (link !== currentLocation) {
            navigation("/" + link);
        }
    };

    return menuItems.map((value, index) => {
        const isActive = currentLocation === value.link;
        return (
            <ListItem
                key={index}
                disablePadding
                sx={{
                    height: "40px",
                    color: (theme) =>
                        isActive ? theme.palette.primary.main : "white",
                    backgroundColor: isActive ? grey[900] : "black",
                }}
            >
                <ListItemButton
                    disabled={disabled}
                    onClick={() => clickMenuLink(value.link)}
                >
                    <ListItemIcon sx={{ color: "inherit" }}>
                        {value.icon}
                    </ListItemIcon>
                    <MenuItemTransition in={drawerOpen}>
                        <ListItemText
                            sx={{
                                color: "white",
                                transition: layoutOptions.drawerTransition,
                                overflow: "hidden",
                                flexWrap: "nowrap",
                                flexShrink: "0",
                                fontWeight: isActive ? "bold" : "normal",
                            }}
                            disableTypography
                            primary={value.text}
                        />
                    </MenuItemTransition>
                </ListItemButton>
            </ListItem>
        );
    });
}
