import { Grid } from "@mui/material";
import { Fragment } from "react";

export default function DemoItem(props) {
    const { value, label, suffix } = props;
    return (
        <Fragment>
            <Grid item sm={6} lg={4}>
                <div
                    style={{
                        fontWeight: "bold",
                        fontSize: "48px",
                        color: "#e12744",
                        textTransform: "capitalize",
                        textAlign: "left",
                    }}
                >
                    {value}
                </div>
                <div
                    style={{
                        marginTop: "-18px",
                        fontWeight: "normal",
                        fontSize: "24px",
                        color: "#ddd",
                    }}
                >
                    {label}
                    {suffix}
                </div>
            </Grid>
        </Fragment>
    );
}
