import { Box, Divider, List } from "@mui/material";
import React, { Fragment } from "react";
import useWindowSize from "../../../lib/data/useWindowSize";
import DrawerContainer from "./DrawerContainer";
import MenuIcon from "./MenuIcon";
import MenuItems from "./MenuItems";
import MenuWidgets from "./MenuWidgets";

export default function Menu(props) {
    const { windowWidth, windowHeight } = useWindowSize();
    const shortWindow = windowHeight < 768;

    return (
        <Box sx={{ position: "relative" }}>
            <DrawerContainer {...props}>
                <List
                    sx={{
                        marginTop: 0,
                    }}
                >
                    <MenuIcon {...props} />
                    <MenuItems {...props} />
                </List>
                <Fragment>
                    <Divider
                        sx={{
                            width: "100%",
                        }}
                    />
                    <MenuWidgets {...props} />
                    <Divider
                        sx={{
                            width: "100%",
                        }}
                    />
                </Fragment>
            </DrawerContainer>
        </Box>
    );
}
