import MoreButtonIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, FormControlLabel, Grow, MenuItem, Select, Switch, TextField } from "@mui/material";
import { has } from "lodash";
import React, { ComponentProps, Fragment, useEffect, useRef, useState } from "react";
import useData from "../../store/useData";
import ButtonCancel from "../layout/buttons/ButtonCancel";
import ButtonContainer from "../layout/buttons/ButtonContainer";
import ButtonMore from "../layout/buttons/ButtonMore";
import ButtonSave from "../layout/buttons/ButtonSave";
import Card from "../layout/components/Card";
import FormControl from "../layout/components/FormControl";
import GridContainer from "../layout/grid/GridContainer";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";
import GridRow from "../layout/grid/GridRow";
import SectionTitle from "../layout/SectionTitle";
import LoadProfileGraph from "./LoadProfileGraph";
import NodePropertySection from "./NodePropertySection";

/**
 *
 * @param {ComponentProps} props
 * @param {function} closeModalAction
 * @returns
 */
export default function NodeEdit(props) {
    const { saveHandler, cancelHandler, node } = props;

    const loadPresets = useData().presets().sort();

    const [loadProfileOption, setLoadProfileOption] = useState("custom");
    const [isDirty, setIsDirty] = useState(false);
    const [formData, setFormData] = useState({});
    const [showMore, setShowMore] = useState(false);
    const [loadProfileGraphical, setLoadProfileGraphical] = useState(false);
    const [currentLoadProfile, setCurrentLoadProfile] = useState([0, 0, 0]);
    const containerRef = useRef(null);

    useEffect(() => {
        const newLoadProfileData = String(node.load_profile)
            .split(",")
            .map((value) => parseFloat(value));

        setCurrentLoadProfile(Object.assign({}, newLoadProfileData));
    }, []);

    useEffect(() => {
        console.log("load profile change");
        console.log(currentLoadProfile);
        // if (!loadProfileData || loadProfileData.length < 1) {
        //     return;
        // }

        // const newValue = { load_profile: loadProfileData.join(",") };
        // console.log(newValue);

        // setFormData((s) => {
        //     return {
        //         ...s,
        //         ...newValue,
        //     };
        // });
    }, [currentLoadProfile]);

    useEffect(() => {
        console.log("form data change");
        console.log(formData);
    }, [formData]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (saveHandler) {
            saveHandler(formData);
        }
    };

    const handleCancel = () => {
        if (cancelHandler) {
            cancelHandler();
        }
    };

    const handleFormChange = (e) => {
        const { name, value, type } = e.target;
        setIsDirty(true);

        if (name === "load_profile_type") {
            setLoadProfileOption(value);
            if (value !== "custom") {
                const values_to_strip = ["load_profile", "solar_capacity", "min_dispatch_gen", "max_dispatch_gen"];
                const cloneFormData = { ...formData };
                let valuesStripped = 0;
                values_to_strip.forEach((value) => {
                    if (has(cloneFormData, value)) {
                        valuesStripped++;
                        delete cloneFormData[`${value}`];
                    }
                });
                if (valuesStripped > 1) {
                    setFormData(cloneFormData);
                    return;
                }
            }
        }

        setFormData((s) => {
            const newValue = { [`${name}`]: value };

            return s ? { ...s, ...newValue } : { ...newValue };
        });
    };

    const changeLoadProfileGraphical = (e) => {
        const { name, value, type, checked } = e.target;
        if (value === "graphical") {
            setLoadProfileGraphical(checked);
        }
    };

    const handleLoadProfileChange = (hour, loadValue) => {
        // setIsDirty(true);
        // console.log("Setting load profile", hour, loadValue);
        console.log(currentLoadProfile);
        // newLoadProfileData[hour] = loadValue;
        // setLoadProfileData(newLoadProfileData);
        const e = {
            target: {
                name: "load_profile",
                value: "",
                type: "",
            },
        };
        // handleFormChange
    };

    const handleMore = (e) => {
        setShowMore((s) => !s);
    };

    const nodeProperties = [
        ["Solar Capacity", "solar_capacity"],
        ["Min Generation", "min_dispatch_gen"],
        ["Max Generation", "max_dispatch_gen"],
    ];
    const nodeMoreProperties = [
        ["Wind Rated Power", "wind_rated_power"],
        ["Inertia", "inertia"],
        ["Dispatch Charge Rate", "dispatch_charge_rate"],
        ["Storage Min", "storage_min"],
        ["Storage Max", "storage_max"],
        ["Transmission Loss", "transmission_loss"],
    ];

    if (!node) {
        return null;
    }

    return (
        <Box>
            <form onSubmit={handleFormSubmit}>
                <Card>
                    <GridContainer>
                        <GridRow>
                            <SectionTitle>Edit Node</SectionTitle>
                        </GridRow>
                        <GridItem1>Node Name</GridItem1>
                        <GridItem2>
                            <FormControl>
                                <TextField
                                    type="text"
                                    name="nick_name"
                                    defaultValue={node.nick_name}
                                    onChange={handleFormChange}
                                    inputProps={{ maxLength: 32 }}
                                />
                            </FormControl>
                        </GridItem2>
                        <GridItem1>MAC Address</GridItem1>
                        <GridItem2>
                            <FormControl>
                                <TextField type="text" name="id" value={node.id} disabled />
                            </FormControl>
                        </GridItem2>
                        <GridRow>
                            <ButtonContainer>
                                <ButtonCancel onClick={handleCancel} />
                                <ButtonSave disabled={!isDirty} type="submit" />
                            </ButtonContainer>
                        </GridRow>
                    </GridContainer>
                </Card>
                <Card>
                    <GridContainer>
                        <GridRow>
                            <SectionTitle>Configuration</SectionTitle>
                        </GridRow>

                        <GridItem1>Load Profile Preset</GridItem1>
                        <GridItem2>
                            <FormControl fullWidth>
                                <Select
                                    id="load_profile_type"
                                    name="load_profile_type"
                                    value={loadProfileOption}
                                    onChange={handleFormChange}
                                >
                                    {loadPresets.map((value, index) => (
                                        <MenuItem key={"load_profile_type_" + index} value={value}>
                                            {String(value)}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="custom">Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </GridItem2>
                        {loadProfileOption === "custom" && (
                            <Fragment>
                                {/* <GridItem1>Modify Load Profile</GridItem1>
                                <GridItem2>
                                    <FormControl>
                                        <FormControlLabel
                                            name="loadProfileGraphical"
                                            value="graphical"
                                            label="Graphical"
                                            sx={{
                                                color: loadProfileGraphical ? "inherit" : "gray",
                                            }}
                                            checked={loadProfileGraphical}
                                            control={<Switch size="large" />}
                                            onChange={changeLoadProfileGraphical}
                                        />
                                    </FormControl>
                                </GridItem2> */}
                                {/* {loadProfileGraphical && (
                                    <Fragment>
                                        <GridItem1>Load Profile</GridItem1>
                                        <GridItem2>
                                            <LoadProfileGraph
                                                loadProfile={currentLoadProfile}
                                                changeHandler={handleLoadProfileChange}
                                            />
                                        </GridItem2>
                                    </Fragment>
                                )} */}
                                {!loadProfileGraphical && (
                                    <Fragment>
                                        <NodePropertySection
                                            name="load_profile"
                                            label={"Load Profile"}
                                            defaultValue={node.load_profile}
                                            onChange={handleFormChange}
                                        />
                                    </Fragment>
                                )}

                                <GridItem1>Node Type</GridItem1>
                                <GridItem2>
                                    <FormControl fullWidth>
                                        <Select
                                            id="node_type_select"
                                            name="node_type"
                                            defaultValue={node.node_type}
                                            onChange={handleFormChange}
                                        >
                                            <MenuItem value="custom">Custom</MenuItem>
                                            <MenuItem value="house">House</MenuItem>
                                            <MenuItem value="factory">Factory</MenuItem>
                                            <MenuItem value="office">Office</MenuItem>
                                            <MenuItem value="generator">Generator</MenuItem>
                                            <MenuItem value="storage">Storage</MenuItem>
                                            <MenuItem value="grid">Grid</MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem2>
                                {nodeProperties.map((value, index) => {
                                    const [label, propName] = value;
                                    return (
                                        <NodePropertySection
                                            key={"_load_profile_" + index}
                                            name={propName}
                                            label={label}
                                            type={"number"}
                                            defaultValue={node[`${propName}`]}
                                            onChange={handleFormChange}
                                        />
                                    );
                                })}
                            </Fragment>
                        )}

                        <GridItem1>
                            <ButtonMore
                                onClick={handleMore}
                                startIcon={
                                    <MoreButtonIcon
                                        sx={{
                                            transition: "ease-in-out 150ms",
                                            transform: showMore ? "rotate(180deg)" : "rotate(0deg)",
                                        }}
                                    />
                                }
                            />
                        </GridItem1>
                        <GridItem2>
                            <ButtonContainer>
                                <ButtonCancel onClick={handleCancel} />
                                <ButtonSave disabled={!isDirty} type="submit" />
                            </ButtonContainer>
                        </GridItem2>
                    </GridContainer>
                    <Grow in={showMore} container={containerRef.current} mountOnEnter unmountOnExit>
                        <Box sx={{ mt: "18px" }}>
                            <GridContainer>
                                {nodeMoreProperties.map((value, index) => {
                                    const [label, propName] = value;
                                    return (
                                        <NodePropertySection
                                            key={"_more_node_props" + index}
                                            name={propName}
                                            label={label}
                                            type={"number"}
                                            defaultValue={node[`${propName}`]}
                                            onChange={handleFormChange}
                                        />
                                    );
                                })}
                            </GridContainer>
                        </Box>
                    </Grow>
                </Card>
            </form>
        </Box>
    );
}
