import { useEffect, useState } from "react";
import { useApiGet } from "../api/useApiRequest";

export default function withFetchData(endpoint, options) {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const data = useApiGet(endpoint, options);

    useEffect(() => {
        if (isDataLoaded || data.isLoading || !data.data) {
            return;
        }
        setIsDataLoaded(true);
    }, [data]);

    return [isDataLoaded, data];
}
