import { Fragment } from "react";
import NumberFormat from "react-number-format";
import GridItem1 from "../layout/grid/GridItem1";
import GridItem2 from "../layout/grid/GridItem2";
import NodePropertyInput from "./NodePropertyInput";

export default function NodePropertySection(props) {
    const label = props.label;
    const newProps = {
        ...props,
    };
    delete newProps.label;
    return (
        <Fragment>
            <GridItem1>{label}</GridItem1>
            <GridItem2>
                {props?.name && <NodePropertyInput {...newProps} />}
                {!props?.name && (
                    <NumberFormat
                        value={props.value ?? props.defaultValue ?? 0}
                        displayType={"text"}
                        decimalScale={1}
                        fixedDecimalScale={true}
                    />
                )}
            </GridItem2>
        </Fragment>
    );
}
