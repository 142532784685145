import { Fragment, useEffect } from "react";
import useFetchSystemTopologyData from "../../lib/data/useFetchSystemTopologyData";
import dataStore from "../../store/store";

export default function TopologyData() {
    const [isSystemTopologyDataLoaded, systemTopologyData] = useFetchSystemTopologyData();

    useEffect(() => {
        console.log("topology data changed");
        const topology = systemTopologyData.data.topology;
        dataStore.set.topology(topology);

    }, [systemTopologyData]);
    return <Fragment></Fragment>;
}
