import { useMqttState } from "mqtt-react-hooks";
import { Fragment, useEffect } from "react";
import { useQueue } from "react-use";
import dataStore, { actions } from "../../store/store";
import { has } from "lodash";
import MqttHelpers, { MqttQos } from "../../lib/mqtt/PiMqttHelpers";

export default function MqttQueueHandler(props) {
    const isConnected = dataStore.use.isMqttConnected();

    const publishQueue = useQueue();
    const mqttState = useMqttState();

    /**
     * Add a message to the publishing queue
     *
     */
    const handlePublishRequest = ({ topic, message, qos }) => {
        const newItem = { topic: String(topic), message: String(message), qos: qos ?? MqttQos.DEFAULT };
        publishQueue.add(newItem);
    };

    // Process publishing queue
    useEffect(() => {
        if (!mqttState || !mqttState.client || !publishQueue || publishQueue.size < 1) {
            return;
        }

        const item = publishQueue.first;
        publishQueue.remove();
        if (item === undefined || !item) {
            return;
        }
        let opts = undefined;
        if (has(item, "qos")) {
            opts = { qos: item.qos };
        }
        mqttState.client.publish(item.topic, item.message, opts);
    }, [publishQueue]);

    useEffect(() => {
        actions.data.enqueueMqttMessage(handlePublishRequest);
    }, []);

    return <Fragment>{props.children}</Fragment>;
}
