import React, { useEffect, useState } from "react";
import GridContainer from "../layout/grid/GridContainer";
import EnvironmentSandboxSection from "./EnvironmentSandboxSection";
import useData from "../../store/useData";
import { has } from "lodash";

export default function EnvironmentSandbox(props) {
    const environmentData = useData().simEnvironment();

    const { environmentDataChangeHandler } = props;
    const { irradiance, temperature, wind_speed } = environmentData;

    const [envData, setEnvData] = useState({
        irradiance: 0,
        temperature: 0,
        wind_speed: 0,
    });

    const [commitTimeout, setCommitTimeout] = useState(null);
    const [awaitingCommit, setAwaitingCommit] = useState(false);

    const changeDataValue = (e) => {
        if (!has(e, "target")) {
            console.error("Event error");
            console.error(e);
        }
        const target = e.target.name;
        const value = e.target.value;
        if (!has(envData, target)) {
            return;
        }
        const newItem = { [`${target}`]: value };
        if (commitTimeout) {
            clearTimeout(commitTimeout);
        }
        setEnvData((s) => {
            return {
                ...s,
                ...newItem,
            };
        });
        setAwaitingCommit(true);
        setCommitTimeout(
            setTimeout(() => {
                commitChangeDataValue(newItem);
            }, 250)
        );
        setTimeout(() => {
            setAwaitingCommit(false);
        }, 500);
    };

    useEffect(() => {
        if (commitTimeout || awaitingCommit) {
            return;
        }
        const newData = [
            irradiance ? { irradiance: parseFloat(irradiance) } : null,
            temperature ? { temperature: parseFloat(temperature) } : null,
            wind_speed ? { wind_speed: parseFloat(wind_speed) } : null,
        ];
        const updateData = newData.filter((value) => {
            return value !== null;
        });
        if (!updateData || !updateData.length) {
            return;
        }
        let pushData = {};
        updateData.forEach((value) => {
            pushData = { ...pushData, ...value };
        });
        setEnvData((s) => {
            return { ...s, ...pushData };
        });
    }, [environmentData]);

    const commitChangeDataValue = (value) => {
        environmentDataChangeHandler(value);
        setCommitTimeout(null);
    };

    return (
        <GridContainer>
            <EnvironmentSandboxSection
                name="irradiance"
                label="Irradiance"
                colour="#ffbf00"
                unitHtml="W/m<sup>2</sup>"
                dataValue={envData.irradiance}
                dataValueChange={changeDataValue}
                step={0.1}
                min={0}
                max={1000}
            />

            <EnvironmentSandboxSection
                name="temperature"
                label="Temperature"
                colour="#e12744"
                unitHtml="&deg;C"
                dataValue={envData.temperature}
                dataValueChange={changeDataValue}
                step={0.1}
                min={0}
                max={100}
            />

            <EnvironmentSandboxSection
                name="wind_speed"
                label="Wind Speed"
                colour="#00aff0"
                unitHtml="km/h"
                dataValue={envData.wind_speed}
                dataValueChange={changeDataValue}
                step={0.1}
                min={0}
                max={100}
                hideSensors={true}
            />
        </GridContainer>
    );
}
