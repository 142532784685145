// import BatteryIcon from "../svg/Battery";
// import PowerStationIcon from "../svg/PowerStation";
// import OfficeIcon from "../svg/Office";
// import HouseIcon from "../svg/House";
// import GridIcon from "../svg/Grid";
// import FactoryIcon from "../svg/Factory";
// import BuildingIcon from "../svg/Building";

import batteryIcon from "../../images/grid/battery.png";
import powerStationIcon from "../../images/grid/power-station.png";
import officeIcon from "../../images/grid/office.png";
import houseIcon from "../../images/grid/house.png";
import gridIcon from "../../images/grid/grid.png";
import factoryIcon from "../../images/grid/factory.png";
import buildingIcon from "../../images/grid/building.png";

import getNodeIconNameByType, { getNodeIconImage } from "../../lib/nodes/node-icons";
import { Box } from "@mui/system";

export default function NodeIcon(props) {
    const { iconName, nodeType } = props;

    if (!iconName && !nodeType) {
        return null;
    }

    if (!iconName) {
        iconName = getNodeIconNameByType(nodeType);
    }

    const icon = getNodeIconImage(iconName);


    return <img src={icon} alt={iconName} style={{ width: "100%", height: "auto" }} />;
}
