import Button from "./Button";

export default function ButtonRefresh(props) {
    return (
        <Button
            color="info"
            onClick={props.onClick}
            disabled={props.disabled}
        >
            Refresh
        </Button>
    );
}
