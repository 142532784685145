import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ButtonRefresh from "./buttons/ButtonRefresh";

export default function ErrorModal() {
    const windowReload = () => {
        window.location.reload(false);
    };
    return (
        <Modal
            sx={{
                zIndex: "5000",
                top: "40%",
            }}
            open={true}
        >
            <Box
                sx={{
                    width: "50%",
                    backgroundColor: "black",
                    color: "white",

                    margin: "auto",
                    padding: "24px",
                    border: "1px solid #f00",
                    borderRadius: "3px",
                }}
            >
                <Box sx={{ fontFamily: "inherit" }}>
                    <Typography variant="h6">Error</Typography>
                    <Typography>
                        An error occurred during initialisation. Please try
                        refreshing the app, or checking with an instructor.
                    </Typography>
                </Box>
                <Box sx={{ marginTop: "12px" }}>
                    <ButtonRefresh onClick={windowReload}></ButtonRefresh>
                </Box>
            </Box>
        </Modal>
    );
}
