import {
    PauseCircleOutline as IconNotRunning,
    PlayCircleOutline as IconRunning,
} from "@mui/icons-material";
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Slide,
    Typography,
} from "@mui/material";
import dataStore from "../../../store/store";
import MenuItemTransition from "./MenuItemTransition";

export default function SimulationStatus() {
    const isRunning = dataStore.use.isSimRunning();
    const isConnected = dataStore.use.isMqttConnected();
    const isInitialised = dataStore.use.isInitialised();

    const icon = isRunning ? (
        <IconRunning color="success" titleAccess="Simulation Running" />
    ) : (
        <IconNotRunning
            color="warning"
            titleAccess="Simulation Not Running"
            className="pulser"
            sx={{ opacity: 0.5 }}
        />
    );

    const text = isRunning ? "Sim Running" : "Sim Idle";

    return (
        <Slide in={isInitialised} appear={false} direction="right">
            <ListItem
                sx={{
                    height: "40px",
                    overflow: "hidden",
                    flexWrap: "nowrap",
                    flexShrink: "0",
                }}
            >
                <ListItemIcon sx={{ ml: "-1px", mr: "1px" }}>
                    {icon}
                </ListItemIcon>
                <MenuItemTransition in={isInitialised}>
                    <ListItemText
                        sx={{
                            overflow: "hidden",
                            flexWrap: "nowrap",
                            flexShrink: "0",
                        }}
                    >
                        <Typography>{text}</Typography>
                    </ListItemText>
                </MenuItemTransition>
            </ListItem>
        </Slide>
    );
}
