import Button from "./Button";

export default function ButtonReset(props) {
    return (
        <Button
            color="warning"
            onClick={props.onClick}
            disabled={props.disabled}
        >
            Reset
        </Button>
    );
}
 